import {convertToSlug} from "../../../helpers/helper";

const VerticalCard = (props) => {
	return (
		<div className={`${props.className ?? ''} rounded-3xl bg-white p-4`}>
			<div className="bg-gradient-to-b from-white to-gray-100 h-full rounded-3xl">
				<img className="w-full rounded-2xl" src={props.image} alt={convertToSlug(props.title)}/>
				<h3
					className={`${props.classTitle ?? ''} italic capitalize text-center text-2xl font-bold -mt-9`}>
					{props.title}
				</h3>
				<p className="text-black italic font-bold p-4 text-center">
					{props.children}
				</p>
			</div>
		</div>
	);
};

export default VerticalCard;
