import { useContext } from 'react';
import { ethers } from "ethers";
import useCryptoUserService from './use-crypto-user-service';
import AuthContext from '../context/auth-context';
import { MsgError } from '../utils/extract-message-error';

const useIntegrationMetamask = (props) => {
    const authenticateService = useCryptoUserService("Authenticate");
    const ctx = useContext(AuthContext);
    const redes = [{ environment: "QA", chainId: '0x61', chainName: "Smart Chain - Testnet", symbol: "BNB", decimals: 18, rpcUrls: "https://data-seed-prebsc-1-s1.binance.org:8545/", blockExplorerUrls: "https://testnet.bscscan.com" },
    { environment: "Production", chainId: '0x38', chainName: "Smart Chain", symbol: "BNB", decimals: 18, rpcUrls: "https://bsc-dataseed.binance.org/", blockExplorerUrls: "https://bscscan.com" }];
    const tokens = [{ environment: "QA", tokenAddress: ctx.keyResources.PetrolCoinSmartContract, tokenSymbol: 'USDT', tokenDecimals: 18, tokenImage: 'http://placekitten.com/200/300' },
    { environment: "Production", tokenAddress: ctx.keyResources.PetrolCoinSmartContract, tokenSymbol: 'USDT', tokenDecimals: 18, tokenImage: 'http://placekitten.com/200/300' }];

    const changeNetwork = async () => {
        let rede = redes.filter(p => p.environment === ctx.keyResources.CurrentEnvironment)[0];            
        try {
            //console.log("antes de mudar de rede ********")
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: rede.chainId }],
            }).then((ok) => {
                //console.log(ok);
                console.log("depois de mudar de rede ********")
            });
        } catch (error) {
            //console.log(error);
            if (error.code === 4902 ||
                error.message === "Unrecognized chain ID \"0x61\". Try adding the chain using wallet_addEthereumChain first." ||
                error.message === "Unrecognized chain ID \"0x38\". Try adding the chain using wallet_addEthereumChain first.") {
                return false;
            }
        }
        //console.log("fim");
        return true;
    }

    switch (props) {
        case 'AuthenticateMetamask':
            return async (captcha) => {
                if (!window.ethereum) {
                    ctx.onMessage("No crypto wallet found!", "error");
                    return false;
                }

                let changeReturn = await changeNetwork();
                if (!changeReturn && ctx.keyResources.CurrentEnvironment === "Production") {
                    ctx.onMessage("Network not configured, click \"Add BSC Network\" before logging in", "error");
                    return false;
                }

                try {
                    ctx.onLoading(true);
                    const message2Sign = ctx.keyResources.WalletAuthenticationMessage;
                    const provider = new ethers.providers.Web3Provider(window.ethereum)
                    provider.send("eth_requestAccounts", [])
                    .then((accounts) => {
                        const signer = provider.getSigner();
                        signer.signMessage(message2Sign)
                        .then((signature) => {
                            //console.log(accounts[0]);
                            const newAddress = ethers.utils.verifyMessage(message2Sign, signature);
                            const data = {
                                Signature: signature,
                                WalletAddress: newAddress,
                                CaptchaToken: captcha
                            }
                            //console.log(data);
                            authenticateService(data).then((res) => {
                                //console.log(res);
                                ctx.onLoading(false);
                                if (res !== null) {
                                    ctx.onLogin(res[0]);
                                }
                            });
                        })
                        .catch((err) => {
                            ctx.onLoading(false);
                            //console.log(err);
                            ctx.onMessage(MsgError(err), "error");
                            return false;
                        });
                    })
                    .catch((err) => {
                        ctx.onLoading(false);
                        if (err.code === 4001) {
                            ctx.onMessage("Please connect to MetaMask!", "error");
                        } else {
                            //console.log(err);
                            ctx.onMessage(MsgError(err), "error");
                        }
                        return false;
                    });
                } catch (err) {
                    ctx.onLoading(false);
                    //console.log(err);
                    // if (err.data && err.data.message) {
                    //     ctx.onMessage(err.data.message, "error");
                    // } else {
                    //     ctx.onMessage(err.message, "error");
                    // }
                    ctx.onMessage(MsgError(err), "error");
                    return false;
                }
            };
        case 'AddNetwork':
            return async () => {
                if (!window.ethereum) {
                    ctx.onMessage("No crypto wallet found!", "error");
                    return;
                }
        
                let rede = redes.filter(p => p.environment === ctx.keyResources.CurrentEnvironment)[0];
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: rede.chainId }],
                    });
                    ctx.onMessage("Success!");
                } catch (error) {
                    if (error.code === 4902 ||
                        error.message === "Unrecognized chain ID \"0x61\". Try adding the chain using wallet_addEthereumChain first." ||
                        error.message === "Unrecognized chain ID \"0x38\". Try adding the chain using wallet_addEthereumChain first.") {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{ 
                                    chainId: rede.chainId,
                                    chainName: rede.chainName,
                                    nativeCurrency: {
                                        name: rede.symbol,
                                        symbol: rede.symbol,
                                        decimals: rede.decimals
                                    },
                                    rpcUrls: [rede.rpcUrls],
                                    blockExplorerUrls: [rede.blockExplorerUrls],
                                    iconUrls: [""]
                                }],
                            });
                            ctx.onMessage("Success!");
                        } catch (addError){
                            //console.log('Did not add network');
                            //console.log(addError);
                            // if (addError.data && addError.data.message) {
                            //     ctx.onMessage(addError.data.message, "error");
                            // } else {
                            //     ctx.onMessage(addError.message, "error");
                            // }
                            ctx.onMessage(MsgError(addError), "error");
                        }
                    }
                    else {
                        //console.log(error);
                        // if (error.data && error.data.message) {
                        //     ctx.onMessage(error.data.message, "error");
                        // } else {
                        //     ctx.onMessage(error.message, "error");
                        // }
                        ctx.onMessage(MsgError(error), "error");
                    }
                }
            };
        case 'ImportToken':
            return async () => {
                if (!window.ethereum) {
                    ctx.onMessage("No crypto wallet found!", "error");
                    return;
                }
        
                let token = tokens.filter(p => p.environment === ctx.keyResources.CurrentEnvironment)[0];
                try {
                    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
                    const wasAdded = await window.ethereum.request({
                        method: 'wallet_watchAsset',
                        params: {
                            type: 'ERC20', // Initially only supports ERC20, but eventually more!
                            options: {
                                address: token.tokenAddress, // The address that the token is at.
                                symbol: token.tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                                decimals: token.tokenDecimals, // The number of decimals in the token
                                image: token.tokenImage, // A string url of the token logo
                            },
                        },
                    });
        
                    if (wasAdded) {
                        ctx.onMessage('Thanks for your interest!');
                    } else {
                        ctx.onMessage('Your loss!', "error");
                    }
                } catch (error) {
                    //console.log(error);
                    // if (error.data && error.data.message) {
                    //     ctx.onMessage(error.data.message, "error");
                    // } else {
                    //     ctx.onMessage(error.message, "error");
                    // }
                    ctx.onMessage(MsgError(error), "error");
                }
            };
        default:
            return null;
    }
};

export default useIntegrationMetamask;