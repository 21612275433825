import Coins from "../Coins";

const HorizontalCard = (props) => {
	return (
		<div className={`${props.className ?? ''} rounded-2xl bg-white text-xs p-2`}>
			<div className=" h-full rounded-3xl flex flex-row align-middle bg-gradient-to-br from-white to-gray-100">
				<img className="w-1/4 rounded-2xl border-0" src={props.image} alt=""/>
				<div className="flex justify-around rounded-r-2xl">
					<div className="w-2/3 text-black italic font-bold p-4 inline-block align-middle">
						{props.children}
					</div>
					<Coins total={props.total} className="w-1/3 mt-4 right-0"/>
				</div>
			</div>
		</div>
	);
};

export default HorizontalCard;
