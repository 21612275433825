import React, { useState, useEffect, useContext } from 'react';
import useGameService from '../../../hooks/use-game-service';
import AuthContext from '../../../context/auth-context';
import { ConvertMsToHM, GetUtcDate } from '../../../utils/date-operators';

const RoundTimer = () => {
	const [roundTimeComponent, setRoundTimeComponent] = useState({ 
		startTime: new Date(), 
		endTime: new Date(), 
		totalPrize: 0,
		hasStarted: false,
		currentTime: -1,
		secondsTrigger: 0
	});
	const getGameRound = useGameService("GetGameRound");
    const ctx = useContext(AuthContext);

	useEffect(() => {

		const calcTimeComponent = (roundData) => {
			if(roundData !== undefined || roundTimeComponent.currentTime > -1){
				let round = undefined;
				if(roundData !== undefined){
					roundTimeComponent.secondsTrigger = 0;
					round = {...roundData, currentTime: 0, secondsTrigger: 0 };
					let dateNow = GetUtcDate();
					let startTime = new Date(round.startTime);
					let endTime = new Date(round.endTime);

					//console.log("dateNow:");
					//console.log(dateNow);
					//console.log("startTime:");
					//console.log(startTime);

					if(round.hasStarted) {
						round.currentTime = endTime.getTime() - dateNow.getTime();
					} else {
						round.currentTime = startTime.getTime() - dateNow.getTime();
					}
				}

				if(roundTimeComponent.currentTime > -1){
					round = {...roundTimeComponent, 
						currentTime: roundTimeComponent.currentTime - 1000,
						secondsTrigger: roundTimeComponent.secondsTrigger + 1
					};
				}

				setRoundTimeComponent(round);
			}
		}

        const getRoundTimeComponent = () => { 
			if(ctx.userData.isLoggedIn){
				if((ctx.urlResources.urlGetGameRound !== undefined && roundTimeComponent.currentTime === -1) || roundTimeComponent.secondsTrigger === 120){
					getGameRound().then((res) => { 
						if (res !== null && res !== undefined) {
							//console.log("antesRound");
							//console.log(res[0]);
							calcTimeComponent(res[0]);
						}
					}); 
				}
				else {
					calcTimeComponent(undefined)
				}
			}
        };

        const intervalRoundComponent = setInterval(() => {
            getRoundTimeComponent();
        }, 1000);
        
        return () => clearInterval(intervalRoundComponent);
    }, [roundTimeComponent, setRoundTimeComponent, getGameRound, ctx]);   	

	return (
		<>
        {ctx.userData.isLoggedIn && <div className="flex justify-left space-x-1.5">
            {roundTimeComponent.hasStarted && <p className="text-white italic font-bold text-shadow m-auto">Ends in: {ConvertMsToHM(roundTimeComponent.currentTime)}</p>}
            {!roundTimeComponent.hasStarted && <p className="text-white italic font-bold text-shadow">Next Round in: {ConvertMsToHM(roundTimeComponent.currentTime)}</p>}
        </div>}
		</>
	);
};

export default RoundTimer;