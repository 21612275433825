import React from 'react';
import ReactDOM from 'react-dom';

import confirmImage from '../../../assets/images/confirmImage.svg';
import questionImage from '../../../assets/images/questionImage.svg';
import errorImage from '../../../assets/images/errorImage.svg';
import CPButton from '../CPButton'

const BackdropModal = (props) => {
    let styleConfig = { 
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        zIndex: 10,
        background: "rgba(0, 0, 0, 0.75)",
    }
    // let styleConfig = { 
    //   position: "absolute",
    //   zIndex: 10,
    //   top: 0,
    //   left: 0,
    //   width: "100%",
    //   height: "100vh",
    //   background: "rgba(0, 0, 0, 0.75)",
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "center"
    // }    
    return <div style={styleConfig} />;    
};

const ModalOverlay = (props) => {
    let styleConfig = { 
        position: "fixed",
        top: "30vh",
        left: "10%",
        width: "80%",
        zIndex: 100,
        overflow: "hidden"
    }
    return (
      <div style={styleConfig} className="w-full sm:w-1/2 rounded-2xl bg-white text-xs p-2">
        <div className="h-full rounded-3xl flex flex-row align-middle bg-gradient-to-br from-white to-gray-100">
          {props.type === "success" && <img className="w-1/4 rounded-2xl border-0" src={confirmImage} alt=""/>}
          {props.type === "question" && <img className="w-1/4 rounded-2xl border-0" src={questionImage} alt=""/>}
          {props.type === "error" && <img className="w-1/4 rounded-2xl border-0" src={errorImage} alt=""/>}
          <div className="w-3/4 flex flex-col">
            <div className="h-full text-4xl text-center p-8">
              {props.message}
            </div>
            {(props.type === "success" || props.type === "error") && 
              <div className="p-8">
                <center><CPButton onButtonClick={props.onConfirm} parameters={[]} text="  OK  " /></center>
              </div>
            }
            {props.type === "question" && 
              <div className="flex flex-row p-8">
                <CPButton className="flex-none" onButtonClick={props.onConfirm} parameters={[1]} text="  YES  " />
                <div className="grow"></div>
                <CPButton className="flex-none" bg='brown' onButtonClick={props.onConfirm} parameters={[2]} text="  NO  " />
              </div>
            }
          </div>
        </div>
      </div>
    );
};

const DialogModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <BackdropModal />,
        document.getElementById('backdrop-root')
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          type={props.type}
          message={props.message}
          onConfirm={props.onConfirm}
        />,
        document.getElementById('overlay-root')
      )}
    </React.Fragment>
  );
};

export default DialogModal;