export function CurrencyFormat(num, qtdDec=1) {
    // let op = ".";
    // if (str !== null && str !== undefined && str.toString().indexOf(op) === -1)
    //     str = str + ".00";

    // let price = +str;
    // let dollarUSLocale = Intl.NumberFormat('en-US');
    // return dollarUSLocale.format(price)
    //return parseFloat(+num).toFixed(+qtdDec)
    var numero = parseFloat(+num).toFixed(+qtdDec).split('.');
    numero[0] = numero[0].split(/(?=(?:...)*$)/).join(',');
    return numero.join('.');
}