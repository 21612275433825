import CSSTransition from "react-transition-group/CSSTransition";
import coinIcon from "../../../assets/images/coin1.svg";
import coalIcon from "../../../assets/images/coal_icon.svg";
import gasIcon from "../../../assets/images/gas_icon.svg";
import petroleumIcon from "../../../assets/images/petrol_icon.svg";
import "../../../assets/css/animation.css";

const Commodity = (props) => {
	const animationTiming = {
		enter: 400,
		exit: 1000
	};
    let img = coinIcon;
    if(props.name === "coal")
        img = coalIcon;
    if(props.name === "gas")
        img = gasIcon;
    if(props.name === "petroleum")
        img = petroleumIcon;
	return (
		<>
		{props.animation !== undefined &&<CSSTransition 
			mountOnEnter 
			unmountOnExit 
			in={props.animation} 
			timeout={animationTiming}
			classNames={{
				enter: '',
				enterActive: 'ChangeInit',
				exit: '',
				exitActive: 'ChangeEnd'
			}}>
				<div className={`Change ${props.className ?? ''}`}>
					<div className="inline-block flex items-center align-middle space-x-2">
						<img src={img} alt={props.name} className="h-6 w-6"/>
						<span className="font-bold italic">{props.total}</span>
					</div>
				</div>				
		</CSSTransition>}
		{props.animation === undefined &&
		<div className={`Change ${props.className ?? ''}`}>
			<div className="inline-block flex items-center align-middle space-x-2">
				<img src={img} alt={props.name} className="h-6 w-6"/>
				<span className="font-bold italic">{props.total}</span>
			</div>
		</div>}
		</>
	);
};

export default Commodity;
