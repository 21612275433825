import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import Auth from "../../Auth";

const Menu = (props) => {
	const ctx = useContext(AuthContext);
	return (
		<div
			id={`${props.id ?? 'menu'}`}
			className="w-full flex-grow space-y-4 lg:space-x-6 lg:flex lg:items-center lg:justify-between lg:w-auto hidden lg:block lg:space-y-0 pt-6 lg:pt-0"
		>
			<ul className="list-reset space-y-4 lg:flex lg:space-y-0 justify-end flex-1 items-center sm:text-sm">
				{ctx.userData.isLoggedIn && (
					<>
						<li className="hidden lg:block mr-3">
							<Link className="text-white italic font-bold" to="user">User</Link>
						</li>
						<li className="hidden lg:block mr-3">
							<Link className="text-white italic font-bold" to="mining">Mining</Link>
						</li>
						<li className="hidden lg:block mr-3">
							<Link className="text-white italic font-bold" to="buy-mining">New Miner</Link>
						</li>

						<li className="hidden lg:block mr-3">
							<Link className="text-white italic font-bold" to="market">Market</Link>
						</li>
						<li className="hidden lg:block mr-3">
							<Link className="text-white italic font-bold" to="ranking">Ranking</Link>
						</li>
						<li className="hidden lg:block mr-3">
							<a className="text-white italic font-bold" href="https://docs.cyberpetroleum.com/" target="_blank" rel="noreferrer">Whitepaper</a>
						</li>

						<li className="block lg:hidden mr-3">
							<Link className="text-white italic font-bold" to="market" onClick={() => props.fecharMenu()}>Market</Link>
						</li>
						<li className="block lg:hidden mr-3">
							<Link className="text-white italic font-bold" to="ranking" onClick={() => props.fecharMenu()}>Ranking</Link>
						</li>
						<li className="block lg:hidden mr-3">
							<a className="text-white italic font-bold" href="https://docs.cyberpetroleum.com/" target="_blank" rel="noreferrer" onClick={() => props.fecharMenu()}>Whitepaper</a>
						</li>
					</>
                )}
 				{!ctx.userData.isLoggedIn && (
					 <>
						<li className="mr-3">
							<a className="text-white italic font-bold" href="/#">Home</a>
						</li>
						<li className="mr-3">
							<a className="text-white italic font-bold" href="https://docs.cyberpetroleum.com/" target="_blank" rel="noreferrer">Whitepaper</a>
						</li>
						<li className="mr-3">
							<a className="text-white italic font-bold"
								href="#miners">Miners</a>
						</li>
						<li className="mr-3">
							<a className="text-white italic font-bold"
								href="#shopping">Shopping</a>
						</li>
						<li className="mr-3">
							<a className="text-white italic font-bold"
								href="#roadmap">Roadmap</a>
						</li>
						<li className="block lg:hidden mr-3">
							<a className="text-white italic font-bold" href="https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955&chainId=56" target="_blank" rel="noreferrer">PancakeSwap</a>
						</li>
					 </>
                )}
			</ul>
			<Auth className="space-x-4"/>
		</div>
	);
};

export default Menu;
