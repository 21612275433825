import PropTypes from 'prop-types';
import "./style.css";
import coalIcon from "../../../assets/images/coal_icon.svg";
import gasIcon from "../../../assets/images/gas_icon.svg";
import petroleumIcon from "../../../assets/images/petrol_icon.svg";

const CPButton = (props) => {
	let img = undefined;
    if(props.name === "coal")
        img = coalIcon;
    if(props.name === "gas")
        img = gasIcon;
    if(props.name === "petroleum")
        img = petroleumIcon;

	let effects = `background-${props.bg}`;
	if(props.behavior === "toggle") {
		if(props.selected === "S"){
			effects = `toggle-${props.bg}-active`;
		} else {
			effects = `toggle-${props.bg}`;
		}
	}

	return (
		<button
		className={`${props.className ?? ''} ${effects} text-${props.textSize} button ${img ? 'inline-block flex items-center align-middle space-x-2' : ''}`}
		type={props.type}
		onClick={(event) => props.onButtonClick(...props.parameters, event)}
		>
			{img && <><img src={img} alt="" className="h-6 w-6"/><span>{props.text}</span></>}
			{!img && props.text}
		</button>		
	);
};

CPButton.propTypes = {
	textSize: PropTypes.string,
	bg: PropTypes.string,
	type: PropTypes.string,
	text: PropTypes.string.isRequired,
	behavior: PropTypes.string.isRequired,
	selected: PropTypes.string
}

CPButton.defaultProps = {
	textSize: 'base',
	bg: 'darkblue',
	type: 'button',
	behavior: 'button',
	selected: 'N'
}

export default CPButton;
