import React, { useState, useEffect, useContext } from 'react';
import useMiningTokenService from '../../hooks/use-mining-token-service';
import AuthContext from '../../context/auth-context';
import Main from '../UI/Main';
import ContentItem from "../UI/ContentItem";
import MiningCard from '../UI/MiningCard';

const Mining = (props) => {
    const [isControlMining, setControlMining] = useState("Init");
    const [list, setList] = useState([]);
    const getMinersStatus = useMiningTokenService("GetMinersStatus");
    const ctx = useContext(AuthContext);
    
    //console.log("Componente Mining atualizado urlGetMinersStatus:" + ctx.urlResources.urlGetMinersStatus);

    useEffect(() => {
        //console.log("Componente Mining criado isControlMining:" + isControlMining);

        if(isControlMining === "default"){
            getMinersStatus().then((res) => { 
                if (res !== null && res !== undefined) {
                    let cols = [];
                    let rows = [];
                    res.forEach((item, i) => {
                        if(i%2===0) {
                            cols.push(item);
                            rows.push(cols);
                        } else {
                            rows[rows.length-1].push(item);
                            cols = [];
                        }
                    });
                    setList(rows);
                }
            }); 
            setControlMining("");
        }

    }, [isControlMining, setControlMining, setList, getMinersStatus]); 

    if(isControlMining === "Init" && ctx.urlResources.urlGetMinersStatus !== undefined){
        setControlMining("default");
    }

    return (
        <Main page="mining">
            <ContentItem title="Monitor mining activities">
                <div className="w-full flex flex-col space-y-6 md:px-20">
                    {
                        list.map((miners, index) => (
                            <div key={miners[0].cryptoUserMinerId} className="flex flex-col px-6 space-y-6 sm:flex-row sm:flex-col-none sm:space-y-0 sm:space-x-6">
                                <MiningCard miner={miners[0]} />
                                {miners[1] && <MiningCard miner={miners[1]} />}
                            </div>
                        ))
                    }
                </div>
            </ContentItem>
        </Main>
    )
}
export default Mining;