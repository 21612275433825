import BannerVideo from "../BannerVideo";
import Content from "../Content";

const Main = (props) => {
	return (
		<main className="relative top-20">
			{props.page && (
				<>
					<Content page={props.page}>
						{props.children}
					</Content>
				</>
			)}
			{!props.page && (
				<>
					<BannerVideo />
					<Content/>
				</>
			)}
			<footer className="w-full bg-blue-900 text-white py-4 flex">
				<div className="flex-auto text-left px-2">Cyber Petroleum - 2023</div>
				<div className="flex-auto text-right px-2"><a target="_blank" rel="noreferrer" href="https://discord.gg/XMJWuFaSD7">Discord</a>|<a target="_blank" rel="noreferrer" href="https://t.me/+1SWtIeYMvD01ZjM0">Telegram</a></div>
			</footer>
		</main>
	);
};

export default Main;
