import React, { useState, useEffect, useContext } from 'react';
import oilPumpJackImage from '../../../assets/images/JackPumpMovementStatic.jpg';
import offshorePlataformImage from '../../../assets/images/OilPlatformStatic.jpg';
import windPowerImage from '../../../assets/images/WindmillStatic.jpg';
import naturalGasPlantImage from '../../../assets/images/NaturalGasIdleStatic.jpg';
import coalMinningImage from '../../../assets/images/CoalMineStatic.jpg';
import roadMap from '../../../assets/images/roadmap_v2_no_bg.png';
import coinPile from '../../../assets/images/icn_coinPile.png';
import icnCoin1 from '../../../assets/images/icn_coin1.png';
import icnCoin2 from '../../../assets/images/icn_coin2.png';
import useGameService from '../../../hooks/use-game-service';
import AuthContext from '../../../context/auth-context';
import { ConvertMsToHM, GetUtcDate } from '../../../utils/date-operators';
import { CurrencyFormat } from '../../../utils/currency-operators';
import ContentItem from "../ContentItem";
import VerticalCard from "../VerticalCard";
import HorizontalCard from "../HorizontalCard";
import Information from "../Information";

const Content = (props) => {
	const [roundTimeContent, setRoundTimeContent] = useState({ 
		startTime: new Date(), 
		endTime: new Date(), 
		totalPrize: 0,
		hasStarted: false,
		currentTime: -1,
		secondsTrigger: 0
	});
	const getGameRound = useGameService("GetGameRound");
	const ctx = useContext(AuthContext);

	useEffect(() => {

		const calcTime = (roundData) => {
			if(roundData !== undefined || roundTimeContent.currentTime > -1){
				let round = undefined;
				if(roundData !== undefined){
					roundTimeContent.secondsTrigger = 0;
					round = {...roundData, currentTime: 0, secondsTrigger: 0 };
					let dateNow = GetUtcDate();
					let startTime = new Date(round.startTime);
					let endTime = new Date(round.endTime);
					if(round.hasStarted) {
						round.currentTime = endTime.getTime() - dateNow.getTime();
					} else {
						round.currentTime = startTime.getTime() - dateNow.getTime();
					}
				}
					
				if(roundTimeContent.currentTime > -1){
					round = {...roundTimeContent, 
						currentTime: roundTimeContent.currentTime - 1000,
						secondsTrigger: roundTimeContent.secondsTrigger + 1
					};
				}

				setRoundTimeContent(round);
			}
		}

        const getRoundTimeContent = () => { 
			if(!ctx.userData.isLoggedIn){
				if((ctx.urlResources.urlGetGameRound !== undefined && roundTimeContent.currentTime === -1) || roundTimeContent.secondsTrigger === 120){
					getGameRound().then((res) => { 
						if (res !== null && res !== undefined) {
							calcTime(res[0]);
						}
					}); 
				}
				else {
					calcTime(undefined)
				}
			}
        };

        const intervalRoundContent = setInterval(() => {
            getRoundTimeContent();
        }, 1000);
        
        return () => clearInterval(intervalRoundContent);
    }, [roundTimeContent, setRoundTimeContent, getGameRound, ctx]);   

	return (
		<section className="w-full bg-gradient-to-b from-secondary-500 to-red-900 min-h-screen h-full">
			{props.page && props.children}
			{!props.page && (
				<>
					{!ctx.userData.isLoggedIn && <div className="container mx-auto pt-6 pb-20">
						<div className="w-full text-center">
							{roundTimeContent.hasStarted && <h2 className="font-bold text-4xl text-white italic uppercase text-shadow py-5">Time until Round Finish: {ConvertMsToHM(roundTimeContent.currentTime)}</h2>}
							{!roundTimeContent.hasStarted && <h2 className="font-bold text-4xl text-white italic uppercase text-shadow py-5">Next Round in: {ConvertMsToHM(roundTimeContent.currentTime)}</h2>}
						</div>
						<div className="w-full text-center pt-6">
							<h2 className="font-bold text-4xl text-white italic uppercase text-shadow py-5">Current Prize: {CurrencyFormat(roundTimeContent.totalPrize, +ctx.gameConfigurations.SystemPrecision).toString()} USDT</h2>
						</div>
					</div>}

					<div className="w-full flex space-y-6 py-10 px-6 md:space-y-0 md:space-x-6 md:px-24">
						<Information title="News" className="w-full" lines="3">
							<ul>
								<li><a href="https://t.me/+1SWtIeYMvD01ZjM0">15/03/2023 - This is the last round before the maintenance period, we will be back soon! Click here to Join our telegram channel and stay tuned!</a></li>
								<li><a href="https://docs.cyberpetroleum.com/game-play-version-1.0-current/ranking">12/03/2023 - New ranking prize distribution, click here for more details</a></li>
								<li><a href="/#"></a>07/03/2023 - Free Miner Season! All players during this season will be starting with a Jack Pump</li>
								<li><a href="/#"></a>07/03/2023 - All miners productivity and prices have been updated, please check the new rates and values before invest</li>
								<li><a href="/#"></a>07/03/2023 - Tether - USDT is now the Official Game Token</li>
								<li><a href="/#"></a>07/03/2023 - All Petrol Coins - PTC were returned to the players wallet, the liquidity pool will be available on packswap for one week</li>
								<li><a href="/#"></a>03/03/2023 - Petrol Coins to be retired! Starting from the next season, the new official token of Cyber Petroleum will be Tether - USDT</li>
								<li><a href="https://t.me/+1SWtIeYMvD01ZjM0">02/03/2023 - New Telegram Group Chat created for the Brazilian Players, Click here and join us!</a></li>
							</ul>
						</Information>
					</div>					

					<ContentItem title="Play to Earn" className="hidden md:block">
						<div className="w-full flex flex-col space-x-12 px-6 md:flex-row md:space-x-6 md:space-x-0 md:px-20 relative">
							<div className="relative w-1/3 min-h-screen">
								<img src={icnCoin1} className="z-20 absolute -top-20 -left-28 w-40" alt=""/>
								<VerticalCard
									className="card-animated card-shadow reverse {/*w-full md:w-1/3*/} z-0 absolute -right-32"
									title="Jack Pump"
									image={oilPumpJackImage}
								>
									Explore this valuable resource quickly and profitably.
								</VerticalCard>
							</div>

							<div className="w-1/3 relative z-10" >
								<img src={coinPile} alt="" className="absolute -bottom-16 -left-16"/>
							</div>

							<div className="relative w-1/3 min-h-screen">
								<VerticalCard
									className="card-animated-reverse card-shadow {/*w-full md:w-1/3*/} z-0 absolute -left-32"
									title="Oil Platform"
									classTitle="text-white"
									image={offshorePlataformImage}
								>
									Interesting alternative to diversify your investment portfolio.
								</VerticalCard>
								<img src={icnCoin2} className="z-20 absolute -top-28 -right-20 w-40" alt=""/>
							</div>
						</div>
					</ContentItem>

					<ContentItem id="minners" title="Miners">
						<div className="w-full flex flex-col space-y-6 px-6 md:flex-row md:space-x-6 md:space-y-0 md:px-20">
							<VerticalCard
								className="w-full md:w-1/2 lg:w-1/3"
								title="Oil Pump Jack"
								image={oilPumpJackImage}
							>
								The extraction of oil on land is carried out through onshore platforms, which are installed after the geological exploration of the region. When the well is not surge, that is, it does not spurt oil only with the pressure of the existing fluids, it is necessary to use pumping units.
							</VerticalCard>

							<VerticalCard
								className="w-full md:w-1/2 lg:w-1/3"
								title="Coal"
								classTitle="text-white"
								image={coalMinningImage}
							>
								Coal is a stone basically composed of carbon and magnesium in the form of bitumen, which has a black or dark brown color and is believed to be the most abundant natural fuel on the planet.
							</VerticalCard>

							<VerticalCard
								className="w-full md:w-1/2 lg:w-1/3"
								title="Natural Gas"
								classTitle="text-white"
								image={naturalGasPlantImage}
							>
								Although it is still not as widely used as oil, natural gas represents the third most consumed fossil fuel in the world and its application extends to both electricity generation and industrial activity, transport, domestic and commercial consumption.
							</VerticalCard>
						</div>
					</ContentItem>

					<ContentItem id="shopping" title="Shopping">
						<div className="w-full flex flex-col space-y-6 md:px-20">
							<div className="flex flex-col px-6 space-y-6 sm:flex-row sm:flex-col-none sm:space-y-0 sm:space-x-6">
								<HorizontalCard className="w-full sm:w-1/2" image={offshorePlataformImage} total="5">
								<b>Oil Platform</b> can offer you a range between <b>38.00 to 40.00 Barrels every 6 hours.</b>
								<br/>								
								The best option available to get profit and collect commodities visting the game few times a day.
								</HorizontalCard>

								<HorizontalCard className="w-full sm:w-1/2" image={oilPumpJackImage} total="2">
									<b>Jack Pump</b> can offer you a range between <b>3.40 to 3.80 Barrels every 30 minutes.</b>
									<br/>
									The cheapest miner to start your business in the oil Industry. For enthusiasts who are looking to stay connecting during the whole the season.
								</HorizontalCard>					
							</div>

							<div className="flex flex-col px-6 space-y-6 sm:flex-row sm:flex-col-none sm:space-y-0 sm:space-x-6">
								<HorizontalCard className="w-full sm:w-1/2" image={naturalGasPlantImage} total="2">
								<b>Gas Industry</b> can offer you a range between <b>450.00 to 480.00 MMBtu every 4 hours.</b>
									<br/>
									A suitable option for those who are looking to invest in Natural Gas and collect their profit few times a day.
								</HorizontalCard>

								<HorizontalCard className="w-full sm:w-1/2" image={coalMinningImage} total="2">
								<b>Coal Mine</b> can offer you a range between <b>5.60 to 6.00 Tons every 3 hours.</b>
									<br/>
									The Coal Miner demands low investment and with moderate interaction, that is your chance to join to this profitable industry.
								</HorizontalCard>							
							</div>

							<div className="flex flex-col px-6 space-y-6 sm:flex-row sm:flex-col-none sm:space-y-0 sm:space-x-6">
								<HorizontalCard className="w-full sm:w-1/2" image={windPowerImage} total="Special Item">
								<b>Wind Power</b> generating a very small quantity of energy in a short range of time. (Not available at Version 1.0)
								</HorizontalCard>

	
							</div>

			
						</div>
					</ContentItem>

					<ContentItem id="roadmap" title="Roadmap" className="img-roadmap-gradient">
						<div className="px-6 md:px-20">
							<img src={roadMap} alt="" className=""/>
						</div>
					</ContentItem>				
				</>
			)}
		</section>
	);
};

export default Content;
