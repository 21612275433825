import React, { useContext } from 'react';
import lootBox from '../../assets/images/lootbox.png';
import useTraderService from '../../hooks/use-trader-service';
import AuthContext from '../../context/auth-context';
import { CurrencyFormat } from '../../utils/currency-operators';
import Main from '../UI/Main';
import ContentItem from "../UI/ContentItem";
import BuyCard from '../UI/BuyCard';

const BuyMining = (props) => {
    const drawNewMiner = useTraderService("DrawNewMiner");
    const ctx = useContext(AuthContext);

    const buy = (id, event) => {
        ctx.onMessage("Are you sure?", "question", (res) => {
            if(res === 1)
            {
                ctx.onLoading(true);
                drawNewMiner().then((res) => { 
                    ctx.onLoading(false);
                    if(res !== null && res !== undefined) {
                        ctx.onMessage(res[0], "success");
                    }
                });
            }
        });
    }
    
    return (
        <Main page="buyMining">
            <ContentItem title="Buy a new miner">
                <div className="w-full flex justify-around space-y-6 px-6 md:flex-row md:space-x-6 md:space-y-0 md:px-20">
                    <BuyCard 
                        className="w-full md:w-1/2 lg:w-1/3"
                        title={""}
                        image={lootBox}
                        total={CurrencyFormat(+ctx.gameConfigurations.LootBoxPrice, +ctx.gameConfigurations.SystemPrecision).toString()}
                        buyFunction={buy}
                        minerType={0}
                    >
                    </BuyCard>
                </div>
            </ContentItem>
        </Main>
    )
}
export default BuyMining;