const Information = (props) => {
	return (
		<div className={`${props.className ?? ''}`}>
			<h2 className="font-bold text-2xl text-white italic uppercase text-shadow text-center py-5">
				{props.title}
			</h2>
			<div className={`w-full h-${+props.lines * 10} bg-gray-700 p-2 text-center text-white`}>{props.children}</div>
		</div>
	);
};

export default Information;
