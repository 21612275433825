import {convertToSlug} from "../../../helpers/helper";

const ContentItem = (props) => {
	return (
		<section
			id={convertToSlug(props.title)}
			className={`${props.className ?? ''} container mx-auto pt-6 pb-20`}
		>
			<hr className="w-full border-2"/>
			<h2 className="font-bold text-4xl text-white italic uppercase text-shadow text-center py-20">
				{props.title}
			</h2>
			{props.children}
		</section>
	);
};

export default ContentItem;
