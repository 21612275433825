import React, { useContext, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import useCryptoUserService from '../../hooks/use-crypto-user-service';
import useGameService from '../../hooks/use-game-service';
import AuthContext from '../../context/auth-context';
import { CurrencyFormat } from '../../utils/currency-operators';
import Main from '../UI/Main';
import ContentItem from "../UI/ContentItem";
import CPSelect from '../UI/CPSelect';

const Ranking = (props) => {
    const [isControlRanking, setControlRanking] = useState("Init");
    const [list, setList] = useState([]);
    const [roundHistory, setRoundHistory] = useState([]);
    const [roundSelected, setRoundSelected] = useState({gameRoundId:0});
    const getRankingCurrentGame = useCryptoUserService("GetRankingCurrentGame");
    const getRanking = useCryptoUserService("GetRanking");
    const getGetGameRoundHistory = useGameService("GetGameRoundHistory");
    const ctx = useContext(AuthContext);
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const [viewModeItens] = useState([
        {value:"stack",label:"Stack"},
        {value:"list",label:"List"}
    ]);
    const [mode, setMode] = useState(isMobile ? "stack" : "list");

    //console.log("Componente Ranking atualizado urlGetRankingCurrentGame:" + ctx.urlResources.urlGetRankingCurrentGame

    useEffect(() => {
        //console.log("Componente Ranking criado isControlRanking:" + isControlRanking);

        if(isControlRanking === "default"){
            getRankingCurrentGame().then((res) => { 
                if (res !== null && res !== undefined) {
                    setList(res[0]);
                }
            });
            getGetGameRoundHistory().then((res) => {
                if (res !== null && res !== undefined) {
                    let rounds = res[0].sort((a, b) => { return b.gameRoundId - a.gameRoundId; }).map((c) => ({gameRoundId: c.gameRoundId, gameRoundNumber: c.gameRoundNumber.toString()}));
                    const newFirstElement = {gameRoundId:0,gameRoundNumber:"Current"};
                    const newArray = [newFirstElement].concat(rounds);
                    setRoundHistory(newArray.slice(0, 10));
                }
            });
            setControlRanking("");
        }

    }, [isControlRanking, setControlRanking, getRankingCurrentGame, setList, getGetGameRoundHistory, setRoundHistory]);

    const loadRanking = async (round, event) => {
        setRoundSelected(round);
        if(round.gameRoundId === 0){
            getRankingCurrentGame().then((res) => { 
                if (res !== null && res !== undefined) {
                    setList(res[0]);
                }
            });
        }
        if(round.gameRoundId > 0){
            getRanking(round.gameRoundId).then((res) => { 
                if (res !== null && res !== undefined) {
                    setList(res[0].sort((a, b) => { return a.rank - b.rank; }));
                }
            });
        }
    }

    if(isControlRanking === "Init" && ctx.urlResources.urlGetRankingCurrentGame !== undefined){
        setControlRanking("default");
    }

    const viewModeChangeHandler = (event) => {
        setMode(event.target.value);
    }

    const roundChangeHandler = (event) => {
        loadRanking({gameRoundId:+event.target.value}, null);
    }

    return (
        <Main page="ranking">
            <ContentItem title="Ranking">
                <div className="w-full flex flex-col space-y-6 md:px-20">
                    
                    <div className="flex md:justify-center space-x-6 py-3 overflow-x-auto">

                        <p className="text-white text-center font-bold">ROUND</p>

                        <CPSelect items={roundHistory.map((round) => ({value:round.gameRoundId.toString(), label:round.gameRoundNumber}))} 
                            value={roundSelected.gameRoundId.toString()} 
                            onChange={roundChangeHandler} />

                        <p className="hidden lg:block text-white text-center font-bold">VIEW MODE</p>

                        <CPSelect className="hidden lg:block" items={viewModeItens} value={mode} onChange={viewModeChangeHandler} />

                    </div>
                    
                    {/* <div className="flex md:justify-center space-x-6 py-3 overflow-x-auto">
                        <CPButton onButtonClick={loadRanking} parameters={[{gameRoundId:0}]} text="Current" behavior="toggle" selected={0 === roundSelected.gameRoundId ? "S" : "N"} />
                        {
                            roundHistory.map((round, index) => (
                                <CPButton key={index} onButtonClick={loadRanking} parameters={[round]} text={round.gameRoundId.toString()} behavior="toggle" selected={round.gameRoundId === roundSelected.gameRoundId ? "S" : "N"} />
                                //<label>{round.gameRoundId},{round.startTime},{round.endTime},{round.totalPrize}</label>
                            ))
                        }
                    </div> */}

                    {mode === "list"&&<div className="flex space-x-6 text-white border-b">
                        <div className="w-1/6 text-center">Position</div>
                        <div className="w-1/6 text-ellipsis overflow-hidden">Nick</div>
                        <div className="w-1/6 text-ellipsis overflow-hidden">Wallet</div>
                        <div className="w-1/6 text-center">Miners</div>
                        <div className="w-1/6 text-right">Amount USD</div>
                        <div className="w-1/6 text-right">Coal</div>
                        <div className="w-1/6 text-right">Gas</div>
                        <div className="w-1/6 text-right">Petroleum</div>
                        <div className="w-1/6 text-right">
                            {roundSelected.gameRoundId === 0 &&"Prize (USDT)"} 
                            {roundSelected.gameRoundId > 0 && "Prize (USDT)"} 
                        </div>
                    </div>}

                    {
                        list.map((player, index) => (
                            <div className="flex space-x-6 text-white border-b" key={index}>
                                {mode === "stack"&&<>
                                    <div className="w-1/4 text-center text-2xl">{player.rank}</div>
                                    <div className="w-3/4">
                                        <div className="flex space-x-1">
                                            <div className="w-1/2 text-ellipsis overflow-hidden">Nick:</div>
                                            <div className="w-1/2">{player.nickname === null ? "" : player.nickname}</div>
                                        </div>
                                        <div className="flex space-x-1">
                                            <div className="w-1/2">Wallet:</div>
                                            <div className="w-1/2 text-ellipsis overflow-hidden">{player.cryptoWalletAddress}</div>
                                        </div>
                                        <div className="flex space-x-1">
                                            <div className="w-1/2">Miners:</div>
                                            <div className="w-1/2">{player.minersInGame}</div>
                                        </div>
                                        <div className="flex space-x-1">
                                            <div className="w-1/2">Amount USD:</div>
                                            <div className="w-1/2">{CurrencyFormat(player.amount, +ctx.gameConfigurations.SystemPrecision).toString()}</div>
                                        </div>
                                        <div className="flex space-x-1">
                                            <div className="w-1/2">Coal:</div>
                                            <div className="w-1/2">{CurrencyFormat(player.coal, +ctx.gameConfigurations.SystemPrecision).toString()}</div>
                                        </div>
                                        <div className="flex space-x-1">
                                            <div className="w-1/2">Gas:</div>
                                            <div className="w-1/2">{CurrencyFormat(player.naturalGas, +ctx.gameConfigurations.SystemPrecision).toString()}</div>
                                        </div>
                                        <div className="flex space-x-1">
                                            <div className="w-1/2">Petroleum:</div>
                                            <div className="w-1/2">{CurrencyFormat(player.petroleum, +ctx.gameConfigurations.SystemPrecision).toString()}</div>
                                        </div>
                                        <div className="flex space-x-1">
                                            <div className="w-1/2">
                                                {roundSelected.gameRoundId === 0 &&"Prize (USDT):"} 
                                                {roundSelected.gameRoundId > 0 &&"Prize (USDT):"} 
                                            </div>
                                            <div className="w-1/2">
                                                {roundSelected.gameRoundId === 0 &&CurrencyFormat(player.currentPrize, +ctx.gameConfigurations.SystemPrecision).toString()}
                                                {roundSelected.gameRoundId > 0 &&CurrencyFormat(player.prize, +ctx.gameConfigurations.SystemPrecision).toString()}
                                            </div>
                                        </div>
                                    </div>
                                </>}
                                {mode === "list"&&<>
                                    <div className="w-1/6 text-center">{player.rank}</div>
                                    <div className="w-1/6 text-ellipsis overflow-hidden">{player.nickname === null ? "" : player.nickname}</div>
                                    <div className="w-1/6 text-ellipsis overflow-hidden">{player.cryptoWalletAddress}</div>
                                    <div className="w-1/6 text-center">{player.minersInGame}</div>
                                    <div className="w-1/6 text-right">{CurrencyFormat(player.amount, +ctx.gameConfigurations.SystemPrecision).toString()}</div>
                                    <div className="w-1/6 text-right">{CurrencyFormat(player.coal, +ctx.gameConfigurations.SystemPrecision).toString()}</div>
                                    <div className="w-1/6 text-right">{CurrencyFormat(player.naturalGas, +ctx.gameConfigurations.SystemPrecision).toString()}</div>
                                    <div className="w-1/6 text-right">{CurrencyFormat(player.petroleum, +ctx.gameConfigurations.SystemPrecision).toString()}</div>
                                    <div className="w-1/6 text-right">
                                        {roundSelected.gameRoundId === 0 &&CurrencyFormat(player.currentPrize, +ctx.gameConfigurations.SystemPrecision).toString()}
                                        {roundSelected.gameRoundId > 0 &&CurrencyFormat(player.prize, +ctx.gameConfigurations.SystemPrecision).toString()}
                                    </div>
                                </>}
                            </div>
                        ))
                    }
                </div>
            </ContentItem>
        </Main>
    )
}
export default Ranking;