import CSSTransition from "react-transition-group/CSSTransition";
import coinTop from "../../../assets/images/coin1.svg";
import coinTop2 from "../../../assets/images/coin2.svg";
import "../../../assets/css/animation.css";

const Coins = (props) => {
	const animationTiming = {
		enter: 400,
		exit: 1000
	};
	let coinImg = coinTop;
	if(props.symbol === "USD")
		coinImg = coinTop2;
	return (
		<>
		{props.animation !== undefined &&<CSSTransition 
			mountOnEnter 
			unmountOnExit 
			in={props.animation} 
			timeout={animationTiming}
			classNames={{
				enter: '',
				enterActive: 'ChangeInit',
				exit: '',
				exitActive: 'ChangeEnd'
			}}>
				<div className={`Change ${props.className ?? ''}`}>
					<div className="inline-block flex flex-wrap items-center align-middle space-x-2">
						<img src={coinImg} alt="coin" className="h-6 w-6"/>
						<span className="font-bold italic">{`$ ${props.total}`} {props.symbol ?? ''}</span>
					</div>
				</div>			
		</CSSTransition>}
		{props.animation === undefined &&
		<div className={`${props.className ?? ''}`}>
			<div className="inline-block flex flex-wrap items-center align-middle space-x-2">
				<img src={coinImg} alt="coin" className="h-6 w-6"/>
				<span className="font-bold italic">{`$ ${props.total}`} {props.symbol ?? ''}</span>
			</div>
		</div>}
		</>
	);
};

export default Coins;
