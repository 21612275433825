import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../context/auth-context';

const useGameService = (props) => {
    const navigate = useNavigate();
    const ctx = useContext(AuthContext);

    function errorValidation(error) {
        if(error.response !== null && error.response !== undefined){
            if(error.response.status === 401){
                ctx.onLoading(false);
                ctx.onLogout();
                navigate('/home');
                return;
            }
            if(error.response.status === 400){
                let msg = error.response.data;
                if(msg.errors){
                    msg = msg.errors[Object.keys(msg.errors)[0]][0]
                }
                ctx.onMessage(msg, "error");
                return;
            }
        }
        ctx.onMessage("Oops... Something went wrong!", "error");
    }

    switch (props) {
        case 'GetGameConfigurations':
            return () => {
                return axios.get(ctx.urlResources.urlGetGameConfigurations)
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim GetGameConfigurations"); });
            };
        case 'GetGameRound':
            return () => {
                return axios.get(ctx.urlResources.urlGetGameRound)
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim GetGameRound"); });
            };
        case 'GetGameRoundHistory':
            return () => {
                return axios.get(ctx.urlResources.urlGetGameRoundHistory)
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim GetGameRoundHistory"); });
            };            
        default:
            return null;
    }
};

export default useGameService;