import React, { useState, useEffect, useContext } from 'react';
import coalMinningImageStatic from '../../../assets/images/CoalMineStatic.jpg';
import coalMinningImageDynamic from '../../../assets/images/CoalMine.mp4';
import naturalGasPlantImageStatic from '../../../assets/images/NaturalGasIdleStatic.jpg';
import naturalGasPlantImageDynamic from '../../../assets/images/NaturalGasIdle.mp4';
import oilPumpJackImageStatic from '../../../assets/images/JackPumpMovementStatic.jpg';
import oilPumpJackImageDynamic from '../../../assets/images/JackPumpMovement.mp4';
import offshorePlataformImageStatic from '../../../assets/images/OilPlatformStatic.jpg';
import offshorePlataformImageDynamic from '../../../assets/images/OilPlatform.mp4';
import coalIcon from "../../../assets/images/coal_icon.svg";
import gasIcon from "../../../assets/images/gas_icon.svg";
import petroleumIcon from "../../../assets/images/petrol_icon.svg";
import loaging from '../../../assets/images/catavento.gif';
import useMiningTokenService from '../../../hooks/use-mining-token-service';
import useTraderService from '../../../hooks/use-trader-service';
import AuthContext from '../../../context/auth-context';
// import { DateFormat } from '../../../utils/date-operators';
// import { CurrencyFormat } from '../../../utils/currency-operators';
// import { ConvertMsToHM, GetUtcDate } from '../../../utils/date-operators';
import CPButton from "../CPButton";

const MiningCard = (props) => {
	const [miningItem, setMiningItem] = useState(props.miner);
	const [minerDetails] = useState([
		{ id: 1, imgStatic: coalMinningImageStatic, imgDynamic: coalMinningImageDynamic, commodityImage: coalIcon},
		{ id: 2, imgStatic: naturalGasPlantImageStatic, imgDynamic: naturalGasPlantImageDynamic, commodityImage: gasIcon},
		{ id: 3, imgStatic: oilPumpJackImageStatic, imgDynamic: oilPumpJackImageDynamic, commodityImage: petroleumIcon},
		{ id: 4, imgStatic: offshorePlataformImageStatic, imgDynamic: offshorePlataformImageDynamic, commodityImage: petroleumIcon}
	]);
	const [posCollect, setPosCollect] = useState([]);
    const collectTokens = useMiningTokenService("CollectTokens");
	const getMinerStatus = useMiningTokenService("GetMinerStatus");
	const buyCapacityUpgrade = useTraderService("BuyCapacityUpgrade");
	const buyProductionUpgrade = useTraderService("BuyProductionUpgrade");
    const ctx = useContext(AuthContext);

	useEffect(() => {

		const renderCard = (item) => {
			const minerFront = minerDetails.filter(p => p.id === item.minerId)[0];
			let imgStatic = minerFront.imgStatic;
			let imgDynamic = minerFront.imgDynamic;
			let commodityImage = minerFront.commodityImage;

			const newItem = {...item, imgStatic: imgStatic, imgDynamic: imgDynamic, commodityImage: commodityImage};
			setMiningItem(newItem);
		}

        const getMinerStatusTrigger = () => { 
			if(ctx.userData.isLoggedIn){
				if(ctx.urlResources.urlGetMinersStatus !== undefined && miningItem.cryptoUserMinerId !== undefined){
					getMinerStatus(miningItem.cryptoUserMinerId).then((res) => { 
						if (res !== null && res !== undefined) {
							renderCard(res[0]);
						}
					}); 
				}
			}
        };

        const intervalMinersStatus = setInterval(() => {
            getMinerStatusTrigger();
        }, 1000);
        
        return () => clearInterval(intervalMinersStatus);
    }, [miningItem, setMiningItem, getMinerStatus, minerDetails, ctx]);   

	const onUpgradeCapacity = (id, event) => {
		ctx.onMessage("Are you sure?", "question", (res) => {
            if(res === 1)
            {
                ctx.onLoading(true);
				const data = { cryptoUserMinerId: id };
                buyCapacityUpgrade(data).then((res) => { 
                    ctx.onLoading(false);
                    if(res !== null && res !== undefined) {
                        ctx.onMessage(res[0], "success");
                    }
                });
            }
        });
    }

	const onUpgradeProductivity = (id, event) => {
		ctx.onMessage("Are you sure?", "question", (res) => {
            if(res === 1)
            {
                ctx.onLoading(true);
				const data = { cryptoUserMinerId: id };
                buyProductionUpgrade(data).then((res) => { 
                    ctx.onLoading(false);
                    if(res !== null && res !== undefined) {
                        ctx.onMessage(res[0], "success");
                    }
                });
            }
        });
    }

    const onCollect = (id, event) => {
		if(!validateClick(event.clientX, posCollect, setPosCollect)) {
			return;
		}
		
        ctx.onLoading(true);
        const data = { cryptoUserMinerId: id };
        collectTokens(data).then((res) => {
            ctx.onLoading(false);
            if (res !== null && res !== undefined) {
                ctx.onMessage("Success!");
				setMiningItem({...miningItem, currentTime: -1});
            }
        });
    }

	const validateClick = (position, storePos, setStorePos) => {
		//console.log(storePos.join(","));
		if(!storePos.includes(position)){
			let arr = storePos;
			arr.push(position);
			if(arr.length > 14){
				arr.shift();
			}
			setStorePos(arr);
			return true;
		}
		ctx.onMessage("Oops, try again!", "terminator");
		return false;
	}

	return (
		<div className="w-full xl:flex rounded-2xl bg-gradient-to-b from-white to-gray-300 text-xs p-4">
			{miningItem.imgDynamic === undefined &&<img src={loaging} alt="loadeando" width="140px" height="140" />}
			{miningItem.imgDynamic !== undefined &&<>
				{miningItem.totalMined < miningItem.maxCapacity &&<>
				<video className="xl:w-1/4 rounded-2xl border-0" autoPlay loop muted>
					<source src={miningItem.imgDynamic} type="video/mp4"/>
					<source src="movie.ogg" type="video/ogg"/>
					Your browser does not support the video tag.
				</video>
				</>}
				{miningItem.totalMined >= miningItem.maxCapacity &&<img className="xl:w-1/4 rounded-2xl border-0" src={miningItem.imgStatic} alt=""/>}

				<div className="xl:w-full grid grid-cols-1 gap-2 content-center">
					<div className="text-black italic font-bold p-2 inline-block align-middle lg:rounded-r-2xl bg-white">
						<div className="grid grid-cols-2">
							<div className="py-4">
								<label>Maximum Quantity: {miningItem.maxCapacity}</label>
								<br />
								<label>Level: {miningItem.capacityLevel}</label>
								<br />
								<label>Upgrade Price: {miningItem.capacityUpgradePrice}</label>
							</div>
							
							<div className="py-4">
								<CPButton bg='orange' onButtonClick={onUpgradeCapacity} parameters={[miningItem.cryptoUserMinerId]} text="Upgrade Capacity" />
							</div>
						</div>

						<div className="grid grid-cols-2">
							<div className="py-4">
								<label>Production rate: {miningItem.productionRate}</label>
								<br />
								<label>Level: {miningItem.productionLevel}</label>
								<br />
								<label>Upgrade Price: {miningItem.productionUpgradePrice}</label>
							</div>

							<div className="py-4">
								<CPButton bg='orange' onButtonClick={onUpgradeProductivity} parameters={[miningItem.cryptoUserMinerId]} text="Upgrade Productivity" />
							</div>
						</div>
					</div>
					<div className="relative h-16">
						<img src={miningItem.commodityImage} alt="" className="h-16 w-16 absolute bottom-0 left-0"/>
						<div className="h-16 grid grid-cols-1 gap-2 content-center">
							<div className="rounded-r-2xl bg-white pt-2 pr-2 pb-2 pl-8 h-8 ml-8 font-bold">
								<label>{miningItem.totalMined}</label>
							</div>
						</div>
					</div>
				</div>
				<div className="xl:w-1/4 grid grid-cols-1 gap-2 content-center ml-2">
					<CPButton bg='orange' onButtonClick={onCollect} parameters={[miningItem.cryptoUserMinerId]} text="Collect" />
				</div>			
			</>}
		</div>
	);
};

export default MiningCard;