import videoSrc from '../../../assets/images/CyberPetroleumWorld.mkv';

const BannerVideo = (props) => {
	return (
		<div className="w-full lg:h-540 overflow-hidden">
			<video className="w-full" autoPlay loop muted>
				<source src={videoSrc} type="video/mp4"/>
				<source src="movie.ogg" type="video/ogg"/>
				Your browser does not support the video tag.
			</video>
		</div>
	);
};

export default BannerVideo;
