import React, { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import useIntegrationMetamask from '../../../hooks/use-integration-metamask';
import AuthContext from '../../../context/auth-context';
import logo from "../../../assets/images/logo_.svg";
import Menu from "../Menu";
import metaMask from '../../../assets/images/metamask.svg';
import RoundTimer from '../RoundTimer';
import Captcha from "../Captcha";

const Header = () => {
	const [showCaptcha, setShowCaptcha] = useState(false);
	const authenticateMetamask = useIntegrationMetamask("AuthenticateMetamask");
	const addNetwork = useIntegrationMetamask("AddNetwork");
    const importToken = useIntegrationMetamask("ImportToken");
    const ctx = useContext(AuthContext);
	const navigate = useNavigate();
	const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

	const toggleResponsiveMenu = (menuClick) => {
		if (menuClick === true && document.getElementById('responsive-menu').classList.contains('hidden')) {
			return;
		} 

		document.getElementById('responsive-menu').classList.toggle('hidden');
	}

	const logout = () => {
        ctx.onLogout();
        navigate('/home');
    };

	const login = () => {
		//console.log(ctx.keyResources.CaptchaEnabled);
		if(ctx.keyResources.CaptchaEnabled === "false") {
            authenticateMetamask("");
        }
        else {
            setShowCaptcha(true);
        }
    }
    
    const onSuccessCaptcha = (result) => {
        setShowCaptcha(false);
		authenticateMetamask(result);
    }
    
    const onErrorCaptcha = (e) => {
        setShowCaptcha(false);
    }

    const onCloseCaptcha = () => {
        setShowCaptcha(false);
    }

	return (
		<>
			<header id="header" className="w-full {/*bg-blue-900 text-white flex py-4*/}">
				<nav className="flex items-center justify-between flex-wrap bg-blue-900 fixed p-2.5 md:p-6 w-full z-10 top-0">
					<div className="md:flex flex-shrink-0 text-white mr-1 md:mr-6 md:space-x-3 space-y-0.5">
						<a className="text-white no-underline hover:text-white hover:no-underline" href="/#">
							<img src={logo} className="w-60" alt=""/>
						</a>
						<RoundTimer/>

						{!ctx.userData.isLoggedIn &&
						<div className="block lg:hidden flex justify-left space-x-1.5">
							<button className="border-0 rounded-full bg-gradient-to-b from-secondary-500 to-primary-800 px-3 italic text-sm" onClick={() => addNetwork()}>
								<div className="flex items-center">
									<img src={metaMask} className="w-6 h-6" alt=""/><div>Add BSC</div> 
								</div>
							</button>
							<button className="border-0 rounded-full bg-gradient-to-b from-secondary-500 to-primary-800 px-3 italic text-sm" onClick={() => importToken()}>
								<div className="flex items-center">
									<img src={metaMask} className="w-6 h-6" alt=""/><div>Import USDT</div>
								</div>
							</button>
							<button className="border-0 rounded-full bg-gradient-to-b from-secondary-500 to-primary-800 px-3 italic text-sm text-center" onClick={() => login()}>
								Log In
							</button>
						</div>}

						{!ctx.userData.isLoggedIn && isMobile && showCaptcha &&
						<div className="block lg:hidden flex justify-left space-x-1.5">
							<Captcha
								onSuccess={onSuccessCaptcha}
								onError={onErrorCaptcha}
								onClose={onCloseCaptcha}
							/>
						</div>}

						{ctx.userData.isLoggedIn && 
						<div className="block lg:hidden flex justify-left space-x-4">
							<Link className="text-white italic font-bold" to="user" onClick={() => toggleResponsiveMenu(true)}>User</Link>
							<Link className="text-white italic font-bold" to="mining" onClick={() => toggleResponsiveMenu(true)}>Mining</Link>
							<Link className="text-white italic font-bold" to="buy-mining" onClick={() => toggleResponsiveMenu(true)}>New Miner</Link>
							<button className="border-0 rounded-full bg-gradient-to-b from-secondary-500 to-primary-800 px-3 italic text-sm text-center" onClick={() => logout()}>
								Logout
							</button>
						</div>}
					</div>

					<div className="block lg:hidden">
						<button
							id="nav-toggle"
							className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white"
							onClick={toggleResponsiveMenu}
						>
							<svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<title>Menu</title>
								<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
							</svg>
						</button>
					</div>

					<Menu id="responsive-menu" fecharMenu={toggleResponsiveMenu}/>

				</nav>
			</header>
		</>
	);
};

export default Header;
