import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import useCryptoUserService from '../../hooks/use-crypto-user-service';
import useIntegrationMetamask from '../../hooks/use-integration-metamask';
import AuthContext from '../../context/auth-context';
import { CurrencyFormat } from '../../utils/currency-operators';
import Coins from "../UI/Coins";
import Commodity from "../UI/Commodity";
import metaMask from '../../assets/images/metamask.svg';
import pancakeIcon from '../../assets/images/pancakeicon.png';
import Captcha from "../UI/Captcha";

const Auth = (props) => {
    const [isControl, setControl] = useState("Init");
    const [showCaptcha, setShowCaptcha] = useState(false);
    const getUserService = useCryptoUserService("GetUser");
    const authenticateMetamask = useIntegrationMetamask("AuthenticateMetamask");
    const addNetwork = useIntegrationMetamask("AddNetwork");
    const importToken = useIntegrationMetamask("ImportToken");
    const ctx = useContext(AuthContext);
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    useEffect(() => {
        //console.log("Componente Auth criado " + isControl);

        const backgroundUserCallBack = () => { 
            if(ctx.userData.isLoggedIn && ctx.urlResources.urlGetUser !== undefined) {
                //console.log("chamou user callback"); 
                getUserService().then((resUser) => { 
                    if (resUser !== null && resUser !== undefined) {
                        //console.log("atualizou os valores");
                        ctx.onUpdateBalance(+resUser[0].petrolCoins, +resUser[0].coal, +resUser[0].naturalGas, +resUser[0].petroleum, +resUser[0].dollars);
                    }
                });
            }
        };

        const handleEthereum = () => {
            const { ethereum } = window;
            if (ethereum && ethereum.isMetaMask) {
              ctx.onMessage("Ethereum successfully detected!");
            } else {
              ctx.onMessage("Please install MetaMask!");
            }
        }

        if((window.ethereum && isControl === "Default") || (window.ethereum && ctx.userData.isLoggedIn && isControl === "Init")){
            //console.log("escutando a metamask");
            window.ethereum.on('accountsChanged', () => { 
                //console.log("accountsChanged"); 
                setControl("Changed"); 
            });
            window.ethereum.on('chainChanged', () => { 
                //console.log("chainChanged"); 
                setControl("Changed"); 
            });
            setControl("");
        }

        if(!window.ethereum) {
            window.addEventListener('ethereum#initialized', handleEthereum, { once: true, });
            setTimeout(handleEthereum, 3000); // 3 seconds
        }

        const interval = setInterval(() => {
            //console.log("backgroundUserCallBack"); 
            backgroundUserCallBack();
        }, 2000); // 2 seconds
        
        return () => clearInterval(interval);
    }, [isControl, setControl, getUserService, ctx]);

    const pancakeRedirect = () => {
        window.open('https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955&chainId=56', '_blank');
    };

    const logout = () => {
        ctx.onLogout();
        navigate('/home');
    };

    const login = () => {
        //console.log(ctx.keyResources.CaptchaEnabled);
        if(ctx.keyResources.CaptchaEnabled === "false") {
            authenticateMetamask("");
        }
        else {
            setShowCaptcha(true);
        }
    }
    
    const onSuccessCaptcha = (result) => {
        setShowCaptcha(false);
        authenticateMetamask(result);
    }
    
    const onErrorCaptcha = (e) => {
        setShowCaptcha(false);
    }

    const onCloseCaptcha = () => {
        setShowCaptcha(false);
    }

    if(isControl === "Changed" && ctx.userData.isLoggedIn) {
        logout();
        window.location.reload();
    }

    return (
		<div className={`flex ${props.className ?? ''} flex-wrap space-y-0 space-y-1 md:space-y-0`}>
			{ctx.userData.isLoggedIn &&
			    <>
					<div className="flex space-x-2">
						<label className="font-bold italic align-middle" htmlFor="">Wallet:</label>
						<input
							readOnly
							type="text"
							className="h-6 lg:w-20 px-2 text-xs rounded-full text-white italic bg-transparent shadow-inner shadow-gray-500 border border-gray-700"
							value={ctx.userData.walletAddress}
						/>
					</div>

                    <div className="flex justify-left space-x-4">
                        <Commodity name="coal" total={CurrencyFormat(ctx.userData.coal, +ctx.gameConfigurations.SystemPrecision).toString()} animation={ctx.animation} />
                        
                        <Commodity name="gas" total={CurrencyFormat(ctx.userData.naturalGas, +ctx.gameConfigurations.SystemPrecision).toString()} animation={ctx.animation} />
                        
                        <Commodity name="petroleum" total={CurrencyFormat(ctx.userData.petroleum, +ctx.gameConfigurations.SystemPrecision).toString()} animation={ctx.animation} />
                    </div>

					<Coins total={CurrencyFormat(ctx.userData.dollars, +ctx.gameConfigurations.SystemPrecision).toString()} symbol="USD" animation={ctx.animation}/>
                    
                    <Coins total={CurrencyFormat(ctx.userData.petrolCoins, +ctx.gameConfigurations.SystemPrecision).toString()} symbol="USDT" animation={ctx.animation}/>

                    <button className="hidden lg:block border-0 rounded-full bg-gradient-to-b from-secondary-500 to-primary-800 px-3 italic text-sm" onClick={() => logout()}>
						Logout
					</button>
			    </>
			}

			{!ctx.userData.isLoggedIn &&
                <>
                    <button className="hidden lg:block border-0 rounded-full bg-gradient-to-b from-secondary-500 to-primary-800 px-3 italic text-sm" onClick={() => pancakeRedirect()}>
                        <div className="flex flex-row">
                            <img src={pancakeIcon} className="w-5 h-5" alt=""/>&nbsp;PancakeSwap
                        </div>
                    </button>
                    <button className="hidden lg:block border-0 rounded-full bg-gradient-to-b from-secondary-500 to-primary-800 px-3 italic text-sm" onClick={() => addNetwork()}>
                        <div className="flex flex-row">
                            <img src={metaMask} className="w-6 h-6" alt=""/> Add BSC Network
                        </div>
                    </button>
                    <button className="hidden lg:block border-0 rounded-full bg-gradient-to-b from-secondary-500 to-primary-800 px-3 italic text-sm" onClick={() => importToken()}>
                        <div className="flex flex-row">
                            <img src={metaMask} className="w-6 h-6" alt=""/> Import USDT Token
                        </div>
                    </button>
                    {!isMobile && showCaptcha &&<Captcha
                        onSuccess={onSuccessCaptcha}
                        onError={onErrorCaptcha}
                        onClose={onCloseCaptcha}
                    />}
                    <button className="hidden lg:block border-0 rounded-full bg-gradient-to-b from-secondary-500 to-primary-800 px-3 italic text-sm" onClick={() => login()}>
                        Log In
                    </button>
                </>
			}
		</div>
    )
}
export default Auth;
