import "./style.css";

const CPSelect = (props) => {
    return (
        <div className={`${props.className ?? ''} box`}>
            <select value={props.value} onChange={props.onChange}>
                {
                    props.items.map((item, index) => (
                        <option key={index} value={item.value}>{item.label}</option>
                    ))
                }                
            </select>
        </div>	
	);
};

export default CPSelect;