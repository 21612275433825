import {convertToSlug} from "../../../helpers/helper";
import Coins from "../Coins";
import CPButton from "../CPButton";

const BuyCard = (props) => {
	return (
		<div className={`${props.className ?? ''} rounded-3xl bg-white p-4`}>
			<div className="bg-gradient-to-b from-white to-gray-100 h-full rounded-3xl">
				<img className="w-full rounded-2xl" src={props.image} alt={convertToSlug(props.title)}/>
				<h3
					className={`${props.classTitle ?? ''} italic capitalize text-center text-2xl font-bold -mt-9`}>
					{props.title}
				</h3>
				<p className="text-black italic font-bold p-4 text-center">
					{props.children}
				</p>
				<div className="flex justify-around flex-wrap p-4">
					{!props.buttonOnly &&<Coins total={props.total} className="w-1/2 mt-4 right-0"/>}
					<CPButton bg='orange' onButtonClick={props.buyFunction} parameters={[+props.minerType]} text="  Buy  " />
				</div>
			</div>
		</div>
	);
};

export default BuyCard;
