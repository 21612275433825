import React from 'react';
import ReactDOM from 'react-dom';
import loaging from '../../../assets/images/catavento.gif';

const BackdropOverlay = (props) => {

  let styleConfig = { 
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    zIndex: 10,
    background: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
  // let styleConfig = { 
  //   position: "absolute",
  //   top: 0,
  //   left: 0,
  //   width: "100%",
  //   height: "100vh",
  //   zIndex: 10,
  //   background: "rgba(0, 0, 0, 0.75)",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center"
  // }

  return <div style={styleConfig} onClick={props.onConfirm}><img src={loaging} alt="loadeando" width="140px" height="140" /></div>;
};

const Backdrop = (props) => {
  return (
    <React.Fragment>
    {ReactDOM.createPortal(
      <BackdropOverlay onConfirm={props.onConfirm} />,
      document.getElementById('backdrop-root')
    )}
    </React.Fragment>
  );
};
 
export default Backdrop;