import React, { useEffect } from 'react';

const Captcha = (props) => {

    useEffect(() => {

        const mt_jsloadedcb = () =>
        {
            console.log("mt_jsloadedcb()");
        }
          
        const mt_renderedcb = (state) =>
        {
            console.log("mt_renderedcb(state)");
        }

        const mt_verifiedcb = (state) =>
        {
            console.log("mt_verifiedcb(state)");
            // console.log(window.mtcaptcha.getVerifiedToken());
            // console.log(state.verifiedToken);

            if(props.onSuccess instanceof Function){
                props.onSuccess(state.verifiedToken);    
            }
        }  

        const mt_verifyexpiredcb = (state) =>
        {
            console.log("mt_verifyexpiredcb(state)");
            if(props.onClose instanceof Function){
                props.onClose();
            }
        }

        const mt_errorcb = (state) =>
        {
            console.log("mt_errorcb(state)");
            if(props.onError instanceof Function){
                props.onError(state);
            }
        }           

        window.mtcaptchaConfig["sitekey"] = "MTPublic-Kps07ZZT1";
        window.mtcaptchaConfig["widgetSize"] = "mini";
        window.mtcaptchaConfig["jsloaded-callback"] = mt_jsloadedcb;
        window.mtcaptchaConfig["rendered-callback"] = mt_renderedcb;
        window.mtcaptchaConfig["verified-callback"] = mt_verifiedcb;
        window.mtcaptchaConfig["verifyexpired-callback"] = mt_verifyexpiredcb;
        window.mtcaptchaConfig["error-callback"] = mt_errorcb;

        window.mtcaptchaConfig.renderQueue.push('login-captcha');
    });

	return (
        <div id="login-captcha"></div>
	);
};

export default Captcha;
