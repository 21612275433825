import React, { useState, useContext, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field'

import useCommodityService from '../../hooks/use-commodity-service';
import AuthContext from "../../context/auth-context";
import { DateFormat } from '../../utils/date-operators';
import { CurrencyFormat } from '../../utils/currency-operators';
import Main from '../UI/Main';
import ContentItem from "../UI/ContentItem";
import CPButton from '../UI/CPButton';

import coalIcon from "../../assets/images/coal_icon.svg";
import gasIcon from "../../assets/images/gas_icon.svg";
import petroleumIcon from "../../assets/images/petrol_icon.svg";
import upIcon from "../../assets/images/up_icon.png";
import downIcon from "../../assets/images/down_icon.png";

const Market = (props) => {
    const [isControlMkt, setControlMkt] = useState("Init");
    const getCommoditiesPrice = useCommodityService("GetCommoditiesPrice");
    const sellUserCommodities = useCommodityService("SellUserCommodities");
    const [totalAmount, setTotalAmount] = useState(0);
    const [qtdeCommodity, setQtdeCommodity] = useState(0);
    const [commoditySelected, setCommoditySelected] = useState({id: 0, img: "", price: 0, qtde: 0});
    const [commodityPrice, setCommodityPrice] = useState({
        lastUpdate: "0001-01-01T00:00:00", 
        nextUpdate: "0001-01-01T00:00:00", 
        crudeOil: 0,
        crudeOilStatus: "",
        coal: 0,
        coalStatus: "",
        naturalGas: 0,
        cpMetaverseDollarToPTC: 0,
        naturalGasStatus: ""
    });
    const ctx = useContext(AuthContext);

    useEffect(() => {
        if(isControlMkt === "default"){
            setControlMkt("");
            selectCommodity(1, null);
        }
    }, [isControlMkt, setControlMkt]);

    

    const sell = async (event) => {
        ctx.onMessage("Are you sure?", "question", (res) => {
            if(res === 1)
            {
                if (commoditySelected.id === 0){
                    ctx.onMessage("Please inform a commodity!", "error");
                    return;
                }
        
                if (+totalAmount === 0 || qtdeCommodity === 0){
                    ctx.onMessage("Please inform the value!", "error");
                    return;
                }
        
                let obj = {};
                if (commoditySelected.id === 1) {
                    if(qtdeCommodity > ctx.userData.coal){
                        ctx.onMessage("invalid amount!", "error");
                        return;
                    }
                    obj = { coal: qtdeCommodity, gas: 0, petroleum: 0 }
                }
        
                if (commoditySelected.id === 2) {
                    if(qtdeCommodity > ctx.userData.naturalGas){
                        ctx.onMessage("invalid amount!", "error");
                        return;
                    }
                    obj = { gas: qtdeCommodity, coal: 0, petroleum: 0 }
                }
        
                if (commoditySelected.id === 3) {
                    if(qtdeCommodity > ctx.userData.petroleum){
                        ctx.onMessage("invalid amount!", "error");
                        return;
                    }
                    obj = { petroleum: qtdeCommodity, coal: 0, gas: 0 }
                }
        
                ctx.onLoading(true);
                sellUserCommodities(obj).then((res) => { 
                    ctx.onLoading(false);
                    if (res !== null && res !== undefined) {
                        ctx.onMessage("Success!");
                        selectCommodity(selectNextCommodity(commoditySelected.id), null);
                    }
                });                
            }
        });
    }

    const selectNextCommodity = (item) => {
        if(+item + 1 > 3)
            return 1;
        return +item + 1;
    }

    const selectCommodity = async (item, event) => {
        getCommoditiesPrice().then((res) => { 
            if (res !== null && res !== undefined) {
                let price = res[0];
                let commodity = null;
                if (item === 1) {
                    commodity = {
                        id: item,
                        img: coalIcon,
                        price: price.coal,
                        qtde: ctx.userData.coal
                    }
                }
                if (item === 2) {
                    commodity = {
                        id: item,
                        img: gasIcon,
                        price: price.naturalGas,
                        qtde: ctx.userData.naturalGas
                    }
                }
                if (item === 3) {
                    commodity = {
                        id: item,
                        img: petroleumIcon,
                        price: price.crudeOil,
                        qtde: ctx.userData.petroleum
                    }
                }
                setCommoditySelected(commodity);
                setCommodityPrice(price);
                calcTotalAmount(commodity.qtde, commodity.price);
            }
        }); 
    }

    const calcTotalAmount = (qtde, price = 0) => {
        let totalAmount = 0;
        let qtdeValue = "0";

        let qtdeParam = Number.isNaN(+qtde) ? 0 : +qtde;
        let priceParam = (Number.isNaN(+price) || +price === 0) ? +commoditySelected.price : +price;
        if (priceParam > 0){
            totalAmount = (qtdeParam * priceParam);
            qtdeValue = qtde;
        }

        setTotalAmount(totalAmount);
        setQtdeCommodity(qtdeValue);
    }

    const amountChangeHandler = (value, _, values) => {
        calcTotalAmount(value);
    }

    if(isControlMkt === "Init" && ctx.urlResources.urlGetCommoditiesPrice !== undefined){
        setControlMkt("default");
    }

    if(qtdeCommodity === 0 && ctx.userData.coal > 0 && commoditySelected.id === 1){
        selectCommodity(1, null);
    }

    return (
        <Main page="market">
            <div className="md:flex justify-center md:space-x-6">
                <ContentItem title="Market">
                    <div className="w-full flex flex-col space-y-6 md:px-20">
                        {commoditySelected.id > 0 && <div key={commoditySelected.id} className="flex justify-center">
                            <div className="grid place-items-center">
                                <img src={commoditySelected.img} alt="" className="h-32 w-32"/>
                                {commoditySelected.id === 1 &&<p className="text-white text-center">Quantity in stock: <b>{CurrencyFormat(ctx.userData.coal, +ctx.gameConfigurations.SystemPrecision).toString()}</b></p>}
                                {commoditySelected.id === 2 &&<p className="text-white text-center">Quantity in stock: <b>{CurrencyFormat(ctx.userData.naturalGas, +ctx.gameConfigurations.SystemPrecision).toString()}</b></p>}
                                {commoditySelected.id === 3 &&<p className="text-white text-center">Quantity in stock: <b>{CurrencyFormat(ctx.userData.petroleum, +ctx.gameConfigurations.SystemPrecision).toString()}</b></p>}
                            </div>
                        </div>}
                        <div className="flex justify-center space-x-6">
                            <CPButton onButtonClick={selectCommodity} parameters={[1]} text="Coal" name="coal" behavior="toggle" selected={commoditySelected.id === 1 ? "S" : "N"} />
                            <CPButton onButtonClick={selectCommodity} parameters={[2]} text="Gas" name="gas" behavior="toggle" selected={commoditySelected.id === 2 ? "S" : "N"} />
                            <CPButton onButtonClick={selectCommodity} parameters={[3]} text="Petroleum" name="petroleum" behavior="toggle" selected={commoditySelected.id === 3 ? "S" : "N"} />
                        </div>
                        <CurrencyInput className="h-12 w-full px-2 text-base rounded-full text-white italic text-center bg-transparent shadow-inner shadow-gray-500 border border-gray-700" 
                            name="qtdeCommodity" 
                            placeholder="enter the desired value here"
                            allowDecimals={true}
                            allowNegativeValue={false}
                            decimalsLimit={+ctx.gameConfigurations.SystemPrecision}
                            decimalScale={+ctx.gameConfigurations.SystemPrecision}
                            decimalSeparator="." 
                            groupSeparator=","
                            disableGroupSeparators={true}
                            value={qtdeCommodity} 
                            onValueChange={amountChangeHandler}
                        />
                        <p className="text-white text-center">Total: <b>$ {CurrencyFormat(totalAmount, +ctx.gameConfigurations.SystemPrecision)}</b> USD</p>
                        <CPButton onButtonClick={sell} parameters={[]} text="Sell" />
                    </div>
                </ContentItem>

                <div className="hidden lg:block border-solid border-2 border-white mt-6"/>

                <ContentItem title="Price Table">
                    <div className="flex justify-center">
                        <table className="table-auto border-collapse text-white">
                            <thead>
                                <tr>
                                    <th>Commodity</th>
                                    <th>Price</th>
                                    <th>Variation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border-t-2 text-center leading-10">
                                    <td>Coal</td>
                                    <td>$ {CurrencyFormat(commodityPrice.coal, +ctx.gameConfigurations.SystemPrecision)} USD </td>
                                    <td>
                                        {commodityPrice.coalStatus === "up" && <img src={upIcon} alt="" className="h-6 w-6 mx-auto" />}
                                        {commodityPrice.coalStatus === "down" && <img src={downIcon} alt="" className="h-6 w-6 mx-auto" />}
                                    </td>
                                </tr>
                                <tr className="border-t-2 text-center leading-10">
                                    <td>Gas</td>
                                    <td>$ {CurrencyFormat(commodityPrice.naturalGas, +ctx.gameConfigurations.SystemPrecision)} USD </td>
                                    <td>
                                        {commodityPrice.naturalGasStatus === "up" && <img src={upIcon} alt="" className="h-6 w-6 mx-auto" />}
                                        {commodityPrice.naturalGasStatus === "down" && <img src={downIcon} alt="" className="h-6 w-6 mx-auto" />}
                                    </td>
                                </tr>
                                <tr className="border-t-2 text-center leading-10">
                                    <td>Petroleum</td>
                                    <td>$ {CurrencyFormat(commodityPrice.crudeOil, +ctx.gameConfigurations.SystemPrecision)} USD </td>
                                    <td>
                                        {commodityPrice.crudeOilStatus === "up" && <img src={upIcon} alt="" className="h-6 w-6 mx-auto" />}
                                        {commodityPrice.crudeOilStatus === "down" && <img src={downIcon} alt="" className="h-6 w-6 mx-auto" />}
                                    </td>
                                </tr>
                                <tr className="border-t-2 text-center leading-10 text-xs">
                                    <td colSpan={3}>* Last Update: {DateFormat(commodityPrice.lastUpdate)} | Next Update: {DateFormat(commodityPrice.nextUpdate)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ContentItem>
            </div>
        </Main>        
    )
}
export default Market;