import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Backdrop from './components/UI/Backdrop';
import DialogModal from './components/UI/DialogModal';
import Header from "./components/UI/Header";
import AuthContext from './context/auth-context';
import Rotas from './Rotas';

const App = (props) => {
    const [userData, setUserData] = useState({ isLoggedIn: false });
    const [isLoading, setIsLoading] = useState(false);
    const [objModal, setObjModal] = useState({ showDialog: false, message: "", type: "", callback: undefined });
    const [keyResources, setKeyResources] = useState({});
    const [urlResources, setUrlResources] = useState({});
    const [gameConfigurations, setGameConfigurations] = useState({BNBPrecisionPreSale:"0",MinimumPreSale:"0",TokenPriceInBNBPreSale:"0",Withdrawfee:"0",SystemPrecision:"0"});
    const [animation, setAnimation] = useState(true);
    const MySwal = withReactContent(Swal);

    //console.log("Componente App atualizado");

    useEffect(() => {

        //console.log("Componente App criado");

        if(window.location.host.toLowerCase().indexOf("www") >= 0 && window.location.host.toLowerCase().indexOf("cyberpetroleum") >= 0) {
            window.location.href = `https://cyberpetroleum.com`;
        }
        
        if(window.location.protocol.toLowerCase() === "http:" && window.location.host.toLowerCase().indexOf("cyberpetroleum") >= 0) {
            window.location.href = `https://${window.location.host}`;
        }

        const mt_service = document.createElement("script");
        mt_service.src = "https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js";
        mt_service.async = true;
        document.body.appendChild(mt_service);

        const mt_service2 = document.createElement("script");
        mt_service2.src = "https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js";
        mt_service2.async = true;
        document.body.appendChild(mt_service2);

        const baseURL = `${window.location.protocol}//${window.location.host}/app-settings.json`;
        //console.log("baseURL:"+baseURL);
        axios.get(baseURL)
        .then(res => {
            if(res.data){
                const keys = {
                    PlayToEarnWalletAddress: res.data.PlayToEarnWalletAddress,
                    PetrolCoinSmartContract: res.data.PetrolCoinSmartContract,
                    LiquidityWalletAddress: res.data.LiquidityWalletAddress,
                    CurrentEnvironment: res.data.CurrentEnvironment,
                    WalletAuthenticationMessage: res.data.WalletAuthenticationMessage,
                    CaptchaEnabled: res.data.CaptchaEnabled
                };
                const urls = {
                    urlBase: res.data.urlBase,
                   
                    urlGetCommoditiesPrice: `${res.data.urlBase}/api/Commodities/GetCommoditiesPrice`,
                    urlGetCommoditiesPriceHistory: `${res.data.urlBase}/api/Commodities/GetCommoditiesPriceHistory`,
                    urlGetCommoditiesSalesHistory: `${res.data.urlBase}/api/Commodities/GetCommoditiesSalesHistory`,
                    urlSellUserCommodities: `${res.data.urlBase}/api/Commodities/SellUserCommodities`,

                    urlAuthenticate: `${res.data.urlBase}/api/CryptoUser/authenticate`,
                    urlGetUser: `${res.data.urlBase}/api/CryptoUser/GetUser`,
                    urlGetRanking: `${res.data.urlBase}/api/CryptoUser/GetRanking`,
                    urlGetRankingCurrentGame: `${res.data.urlBase}/api/CryptoUser/GetRankingCurrentGame`,
                    urlSetNickName: `${res.data.urlBase}/api/CryptoUser/SetNickName`,
                    urlGetLastCryptoUserTransactions: `${res.data.urlBase}/api/CryptoUser/GetLastCryptoUserTransactions`,

                    urlGetGameConfigurations: `${res.data.urlBase}/api/Game/GetGameConfigurations`,
                    urlGetGameRound: `${res.data.urlBase}/api/Game/GetGameRound`,
                    urlGetGameRoundHistory: `${res.data.urlBase}/api/Game/GetGameRoundHistory`,
                
                    urlStartMiningToken: `${res.data.urlBase}/api/MiningToken/StartMiningToken`,
                    urlCollectTokens: `${res.data.urlBase}/api/MiningToken/CollectTokens`,
                    urlGetMinersStatus: `${res.data.urlBase}/api/MiningToken/GetMinersStatus`,
                    urlGetMinerStatus: `${res.data.urlBase}/api/MiningToken/GetMinerStatus`,
                    urlGetMiners: `${res.data.urlBase}/api/MiningToken/GetMiners`,
                
                    urlReceiveTransaction: `${res.data.urlBase}/api/Trader/ReceiveTransaction`,
                    urlReceivePreSale: `${res.data.urlBase}/api/Trader/ReceivePreSale`,
                    urlBuyNewMiner: `${res.data.urlBase}/api/Trader/BuyNewMiner`,
                    urlWithdrawToken: `${res.data.urlBase}/api/Trader/WithdrawToken`,
                    urlDrawNewMiner: `${res.data.urlBase}/api/Trader/DrawNewMiner`,
                    urlBuyCapacityUpgrade: `${res.data.urlBase}/api/Trader/BuyCapacityUpgrade`,
                    urlBuyProductionUpgrade: `${res.data.urlBase}/api/Trader/BuyProductionUpgrade`,                    
                };
                setKeyResources(keys);
                setUrlResources(urls);
                axios.get(urls.urlGetGameConfigurations)
                .then(respConf => {
                    if(respConf.data){
                        setGameConfigurations(respConf.data[0]);
                    } else {
                        alert("Oops... Something went wrong!");
                    }
                })
                .catch(error => { console.log(error); })
                .finally(() => { console.log("fim GetGameConfigurations"); });
            } else {
                alert("Oops... Something went wrong!");
            }
        })
        .catch(error => { console.log(error); })
        .finally(() => { console.log("fim app-settings"); });

        let usrData = localStorage.getItem("usrdt");
        if(usrData) {
            let usrDt = JSON.parse(usrData);
            setUserData({...usrDt, petrolCoins: 0, coal: 0, naturalGas: 0, petroleum: 0, dollars: 0});
        }
    }, []);

    const logoutHandler = () => {
        //console.log("logoutHandler invocado!");
        setUserData({ isLoggedIn: false, petrolCoins: 0, coal: 0, naturalGas: 0, petroleum: 0, dollars: 0 });
        //remove a sessão do localstorage
        localStorage.removeItem("usrdt");
    };

    const loginHandler = (data) => {
        let usrData = {...data, isLoggedIn: true};
        setUserData(usrData);
        //seta a sessão no localstorage
        localStorage.setItem("usrdt", JSON.stringify(usrData));
    };

    const loadingHandler = (flag) => {
        setIsLoading(flag);
    };

    const updateBalanceHandler = (balance, coal, naturalGas, petroleum, dollars) => {
        let upAnimation = false;
        if (userData.petrolCoins !== balance || userData.coal !== coal || userData.naturalGas !== naturalGas || userData.petroleum !== petroleum || userData.dollars !== dollars){
            upAnimation = true;
            setAnimation(false);
        }
        setUserData({...userData, petrolCoins: balance, coal: coal, naturalGas: naturalGas, petroleum: petroleum, dollars: dollars});
        if(upAnimation)
            setAnimation(true);
    }

    const onMessage = (message, type="success", confirm=undefined) => {
        if (type === "success") {
            MySwal.fire({
                icon: type,
                title: message,
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
        } 
        if (type === "question") {
            MySwal.fire({
                title: message,
                text: '',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed && confirm) {
                    confirm(1);
                }
            });
            // setObjModal({ showDialog: true, message: message, type: type, callback: confirm });
        }
        if (type === "error") {
            MySwal.fire({
                title: message,
                text: '',
                //icon: type,
                imageUrl: `${window.location.protocol}//${window.location.host}/img_erro.png`,
                imageWidth: 400,
                imageHeight: 200,
                // iconHtml: `<img src="${filesEncoded.imgErro}" width="400">`,
                // customClass: {
                //     confirmButton: 'btn btn-success',
                //     cancelButton: 'btn btn-danger'
                // },
                buttonsStyling: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            });
        }
        if (type === "terminator") {
            MySwal.fire({
                icon: "error",
                title: message,
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
        } 
    }

    const onConfirm = (escolha) => {
        setIsLoading(false);
        setObjModal({...objModal, showDialog: false });
        if (objModal.callback)
        {
            objModal.callback(escolha);
        }
    }
//showDialog: false, message: "", type: "", onConfirm: null
    return (
        <AuthContext.Provider value={{ userData: userData, keyResources: keyResources, urlResources: urlResources, gameConfigurations: gameConfigurations, animation: animation,
        onLogout: logoutHandler, 
        onLogin: loginHandler, 
        onLoading: loadingHandler, 
        onUpdateBalance: updateBalanceHandler,
        onMessage: onMessage }}>
            {isLoading && (<Backdrop />)}
            {objModal.showDialog && (<DialogModal type={objModal.type} message={objModal.message} onConfirm={onConfirm} />)}
            <BrowserRouter>
                <div className="app">
			        <Header/>
			        <Rotas/>
                </div>
            </BrowserRouter>
        </AuthContext.Provider>
    );
};
export default App;