import React from 'react';
import { Routes, Route, Navigate } from 'react-router';

import Main from "./components/UI/Main";
import User from "./components/User";
import BuyMining from "./components/BuyMining";
import Mining from './components/Mining';
import Presale from './components/Presale';
import Market from './components/Market';
import Ranking from './components/Ranking';

const Rotas = (props) => {
    return (
        <Routes>
            <Route path='/' element={<Main/>} />
            <Route path='user' element={<User />} />
            <Route path='buy-mining' element={<BuyMining/>} />
            <Route path='mining' element={<Mining />} />
            <Route path='presale' element={<Presale />} />
            <Route path='market' element={<Market />} />
            <Route path='ranking' element={<Ranking />} />
            <Route path='*' element={<Navigate to="/" replace />} />
        </Routes>
    )
}
export default Rotas;