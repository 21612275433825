import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../context/auth-context';

const useCryptoUserService = (props) => {
    const navigate = useNavigate();
    const ctx = useContext(AuthContext);

    function errorValidation(error) {
        if(error.response !== null && error.response !== undefined){
            if(error.response.status === 401){
                ctx.onLoading(false);
                ctx.onLogout();
                navigate('/home');
                return;
            }
            if(error.response.status === 400){
                let msg = error.response.data;
                if(msg.errors){
                    msg = msg.errors[Object.keys(msg.errors)[0]][0]
                }
                ctx.onMessage(msg, "error");
                return;
            }
        }
        ctx.onMessage("Oops... Something went wrong!", "error");
        //ctx.onMessage("Oops... Something went wrong!", "error");
        //ctx.onMessage("Success!");
        //ctx.onMessage("Are you sure?", "question", (res) => {
        //    console.log(res);
        //});
    }

    switch (props) {
        case 'Authenticate':
            return (data) => {
                return axios.post(ctx.urlResources.urlAuthenticate, data)
                .then(response => response.data)
                .catch(error => { 
                    //console.log(error); 
                    ctx.onMessage("Oops... Something went wrong!", "error");
                })
                .finally(() => { console.log("fim authenticate"); });
            };
        case 'GetUser':
            return () => {
                return axios.get(ctx.urlResources.urlGetUser, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim GetUser"); });
            };
        case 'GetRanking':
            return (gameRoundId) => {
                return axios.get(`${ctx.urlResources.urlGetRanking}?gameRoundId=${gameRoundId}`, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim GetRanking"); });
            };
        case 'GetRankingCurrentGame':
            return () => {
                return axios.get(ctx.urlResources.urlGetRankingCurrentGame, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim GetRankingCurrentGame"); });
            };
        case 'SetNickName':
            return (data) => {
                return axios.post(ctx.urlResources.urlSetNickName, data, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim SetNickName"); });
            };            
        case 'GetLastCryptoUserTransactions':
            return () => {
                return axios.get(ctx.urlResources.urlGetLastCryptoUserTransactions, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim GetLastCryptoUserTransactions"); });
            };
        default:
            return null;
    }
};

export default useCryptoUserService;