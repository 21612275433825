import React, { useState, useContext } from 'react';
import CurrencyInput from 'react-currency-input-field'

import { ethers } from "ethers";
import { useNavigate } from 'react-router-dom'
import bep20abi from "../../PetrolCoinABI.json";

import useTraderService from '../../hooks/use-trader-service';
import useCryptoUserService from '../../hooks/use-crypto-user-service';
import AuthContext from '../../context/auth-context';
import Main from '../UI/Main';
import ContentItem from "../UI/ContentItem";
import CPButton from '../UI/CPButton'
import { MsgError } from '../../utils/extract-message-error';

const User = (props) => {
    const withdrawTokenService = useTraderService("WithdrawToken");
    const receiveTransaction = useTraderService("ReceiveTransaction");
    const setNickName = useCryptoUserService("SetNickName");
    const ctx = useContext(AuthContext);
    const [amountWithdraw, setAmountWithdraw] = useState("");
    const [amountMakeDeposit, setAmountMakeDeposit] = useState("");
    const [nickNameValue, setNickNameValue] = useState("");
    const navigate = useNavigate();

    const withdrawToken = (event) => {
        if (+amountWithdraw === 0){
            ctx.onMessage("Please inform the value!", "error");
            return;
        }

        let obj = { amount: +amountWithdraw };
        ctx.onLoading(true);
        withdrawTokenService(obj).then((res) => { 
            ctx.onLoading(false);
            if (res !== null && res !== undefined) {
                ctx.onMessage("Success!");
                setAmountWithdraw("");
            }
        });
    }

    const sendMakeDeposit = (event) => {
        if (!window.ethereum) {
            ctx.onMessage("Please connect to MetaMask!", "error");
            return;
        }

        if (+amountMakeDeposit === 0) {
            ctx.onMessage("Please inform the value!", "error");
            return;
        }

        try {
            ctx.onLoading(true);
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            provider.send("eth_requestAccounts", [])
            .then((accounts) => {
                const signer = provider.getSigner();
                const bep20 = new ethers.Contract(ctx.keyResources.PetrolCoinSmartContract, bep20abi, signer);
                const newAddress = ethers.utils.getAddress(ctx.keyResources.PlayToEarnWalletAddress);
                bep20.transfer(newAddress, ethers.utils.parseEther(amountMakeDeposit))  
                .then((receipt) => {
                    const data = { transactionHash: receipt.hash, referral: "" };
                    receiveTransaction(data).then((res) => { 
                        ctx.onLoading(false);
                        if (res !== null && res !== undefined) {
                            ctx.onMessage("Success!");
                            setAmountMakeDeposit("");
                        }
                    });
                })
                .catch((err) => {
                    console.error(err);
                    ctx.onLoading(false);
                    if (err.code === 4001) {
                        ctx.onMessage("Please connect to MetaMask!", "error");
                    } else {
                        // if (err.data && err.data.message) {
                        //     ctx.onMessage(err.data.message, "error");
                        // } else {
                        //     ctx.onMessage(err.message, "error");
                        // }
                        ctx.onMessage(MsgError(err), "error");
                    }
                });
                
            })
            .catch((err) => {
                console.error(err);
                ctx.onLoading(false);
                ctx.onLogout();
                navigate('/');
                alert('Please connect to MetaMask.');
            });
        } catch (err) {
            console.error(err);
            ctx.onLoading(false);
            // if (err.data && err.data.message) {
            //     ctx.onMessage(err.data.message, "error");
            // } else {
            //     ctx.onMessage(err.message, "error");
            // }
            ctx.onMessage(MsgError(err), "error");
        }
    }

    const sendNickName = (event) => {
        //console.log(nickNameValue);
        if (nickNameValue === ""){
            ctx.onMessage("Please inform the name!", "error");
            return;
        }

        let obj = { nickName: nickNameValue };
        ctx.onLoading(true);
        setNickName(obj).then((res) => { 
            ctx.onLoading(false);
            if (res !== null && res !== undefined) {
                ctx.onMessage("Success!");
                setNickNameValue("");
            }
        });
    }

    const amountWithdrawChangeHandler = (value, _, values) => {
        setAmountWithdraw(value);
    }

    const amountMakeDepositChangeHandler = (value, _, values) => {
        setAmountMakeDeposit(value);
    }

    const nickNameChangeHandler = (event) => {
        setNickNameValue(event.target.value);
    }

    return (
        <Main page="user">
            <div className="md:flex justify-center md:space-x-6">
                <ContentItem title="Nick Name">
                    <div className="w-full flex flex-col space-y-6 md:px-20">
                        <input
                            name="nickName"
                            placeholder="enter the nick name here"
                            type="text"
                            className="h-12 w-full px-2 text-base rounded-full text-white italic text-center bg-transparent shadow-inner shadow-gray-500 border border-gray-700"
                            maxLength="30"
                            value={nickNameValue}
                            onChange={nickNameChangeHandler}
                        />
                        <CPButton onButtonClick={sendNickName} parameters={[]} text="Save" />
                    </div>
                </ContentItem>

                <div className="hidden lg:block border-solid border-2 border-white mt-6"/>

                <ContentItem title="Make a Withdrawal">
                    <div className="w-full flex flex-col space-y-6 md:px-20">
                        <CurrencyInput className="h-12 w-full px-2 text-base rounded-full text-white italic text-center bg-transparent shadow-inner shadow-gray-500 border border-gray-700" 
                            name="amountWithdraw" 
                            placeholder="enter the desired value here"
                            allowDecimals={true}
                            allowNegativeValue={false}
                            decimalsLimit={+ctx.gameConfigurations.SystemPrecision}
                            decimalScale={+ctx.gameConfigurations.SystemPrecision}
                            decimalSeparator="." 
                            groupSeparator=","
                            disableGroupSeparators={true}
                            value={amountWithdraw} 
                            onValueChange={amountWithdrawChangeHandler}
                        />
                        <CPButton onButtonClick={withdrawToken} parameters={[]} text="Withdrawal" />
                        <p className="text-white text-center">*Operation subject to a fee of {ctx.gameConfigurations.Withdrawfee} USDT(s)</p>
                    </div>
                </ContentItem>

                <div className="hidden lg:block border-solid border-2 border-white mt-6"/>

                <ContentItem title="Make a Deposit">
                    <div className="w-full flex flex-col space-y-6 md:px-20">
                        <CurrencyInput className="h-12 w-full px-2 text-base rounded-full text-white italic text-center bg-transparent shadow-inner shadow-gray-500 border border-gray-700" 
                            name="amount" 
                            placeholder="enter the desired value here"
                            allowDecimals={true}
                            allowNegativeValue={false}
                            decimalsLimit={+ctx.gameConfigurations.SystemPrecision}
                            decimalScale={+ctx.gameConfigurations.SystemPrecision}
                            decimalSeparator="." 
                            groupSeparator=","
                            disableGroupSeparators={true}
                            value={amountMakeDeposit} 
                            onValueChange={amountMakeDepositChangeHandler}
                        />
                        <CPButton onButtonClick={sendMakeDeposit} parameters={[]} text="Send" />
                        <p className="text-white text-center">*The deposit can take few seconds to update your account balance.</p>
                    </div>
                </ContentItem>
            </div>
        </Main>
    )
}
export default User;