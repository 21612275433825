export function DateFormat(str) {
    let date = new Date(Date.parse(str));
    return date.toLocaleString('en-US')
}

function PadTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function ConvertMsToHM(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    // 👇️ if seconds are greater than 30, round minutes up (optional)
    //minutes = seconds >= 30 ? minutes + 1 : minutes;

    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    //hours = hours % 24;

    return `${PadTo2Digits(hours)}:${PadTo2Digits(minutes)}:${PadTo2Digits(seconds)}`;
}

export function GetUtcDate() {
    const utcDateDay = new Date().getUTCDate();
    const utcDateMonth = new Date().getUTCMonth();
    const utcDateYear = new Date().getUTCFullYear();
    const utcTime1 = new Date().getUTCHours();
    const utcTime2 = new Date().getUTCMinutes();
    const utcTime3 = new Date().getUTCSeconds();
    return new Date(utcDateYear, utcDateMonth, utcDateDay, utcTime1, utcTime2, utcTime3);
}