import React from 'react';

const AuthContext = React.createContext({
  userData: { isLoggedIn: false },
  keyResources: {},
  urlResources: {},
  gameConfigurations: {},
  animation: true,
  onLogout: () => {},
  onLogin: () => {},
  onLoading: () => {},
  onUpdateBalance: () => {},
  onMessage: () => {}
});

export default AuthContext;