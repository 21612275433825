import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../context/auth-context';

const useTraderService = (props) => {
    const navigate = useNavigate();
    const ctx = useContext(AuthContext);

    function errorValidation(error) {
        if(error.response !== null && error.response !== undefined){
            if(error.response.status === 401){
                ctx.onLoading(false);
                ctx.onLogout();
                navigate('/home');
                return;
            }
            if(error.response.status === 400){
                let msg = error.response.data;
                if(msg.errors){
                    msg = msg.errors[Object.keys(msg.errors)[0]][0]
                }
                ctx.onMessage(msg, "error");
                return;
            }
        }
        ctx.onMessage("Oops... Something went wrong!", "error");
    }    

    switch (props) {
        case 'ReceiveTransaction':
            return (data) => {
                return axios.post(ctx.urlResources.urlReceiveTransaction, data, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim ReceiveTransaction"); });
            };
        case 'ReceivePreSale':
            return (data) => {
                return axios.post(ctx.urlResources.urlReceivePreSale, data, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim ReceivePreSale"); });
            };
        case 'BuyNewMiner':
            return (data) => {
                return axios.post(ctx.urlResources.urlBuyNewMiner, data, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim BuyNewMiner"); });
            };
        case 'WithdrawToken':
            return (data) => {
                return axios.post(ctx.urlResources.urlWithdrawToken, data, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim WithdrawToken"); });
            };
        case 'DrawNewMiner':
            return () => {
                return axios.post(ctx.urlResources.urlDrawNewMiner, null, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim DrawNewMiner"); });
            };
        case 'BuyCapacityUpgrade':
            return (data) => {
                return axios.post(`${ctx.urlResources.urlBuyCapacityUpgrade}?cryptoUserMinerId=${data.cryptoUserMinerId}`, data, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim BuyCapacityUpgrade"); });
            };
        case 'BuyProductionUpgrade':
            return (data) => {
                return axios.post(`${ctx.urlResources.urlBuyProductionUpgrade}?cryptoUserMinerId=${data.cryptoUserMinerId}`, data, { headers: { Authorization: `Bearer ${ctx.userData.token}` } })
                .then(response => response.data)
                .catch(errorValidation)
                .finally(() => { console.log("fim BuyProductionUpgrade"); });
            };    
        default:
            return null;
    }
};

export default useTraderService;