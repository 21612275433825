import React, { useState, useContext } from 'react';
import { ethers } from "ethers";
import CurrencyInput from 'react-currency-input-field'

import useTraderService from '../../hooks/use-trader-service';
import AuthContext from "../../context/auth-context";
import { CurrencyFormat } from '../../utils/currency-operators';
import CPButton from '../UI/CPButton';

const Presale = (props) => {
    const receiveTransaction = useTraderService("ReceivePreSale");
    const [totalAmount, setTotalAmount] = useState(0);
    const [amount, setAmount] = useState("");
    const [referralChannel, setReferralChannel] = useState("");
    const ctx = useContext(AuthContext);

    const send = async (event) => {
        if (!window.ethereum) {
            ctx.onMessage("No crypto wallet found!", "error");
            return;
        }

        if (+amount === 0) {
            ctx.onMessage("Please inform the value!", "error");
            return;
        }

        if (+amount <= 0 || +amount < +ctx.gameConfigurations.MinimumPreSale) {
            ctx.onMessage("Invalid value!", "error");
            return;
        }

        try {
            ctx.onLoading(true);
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = await provider.getSigner();
            ethers.utils.getAddress(ctx.keyResources.LiquidityWalletAddress);
            const receipt = await signer.sendTransaction({
                to: ctx.keyResources.LiquidityWalletAddress,
                value: ethers.utils.parseEther(amount)
            });
            const data = { transactionHash: receipt.hash, referral: referralChannel };
            receiveTransaction(data).then((res) => { 
                ctx.onLoading(false);
                if (res !== null && res !== undefined) {
                    ctx.onMessage(`Operation performed successfully! Receipt: ${receipt.hash}`);
                    setTotalAmount(0);
                    setAmount("0");
                    setReferralChannel("");
                }
            });
        } catch (err) {
            //console.log(err);
            ctx.onLoading(false);
            if (err.data && err.data.message) {
                ctx.onMessage(err.data.message, "error");
            } else {
                ctx.onMessage(err.message, "error");
            }
        }
    }

    const amountChangeHandler = (value, _, values) => {
        let totalAmount = 0;
        let totalValue = "0";
        if (!Number.isNaN(+value)){
            totalAmount = +value / +ctx.gameConfigurations.TokenPriceInBNBPreSale
            totalValue = value;
        }
        setTotalAmount(totalAmount);
        setAmount(totalValue);
    }

    const referralChangeHandler = (event) => {
        setReferralChannel(event.target.value);
    }

    return (
        <div className="w-full flex flex-col space-y-6 md:px-20">
            <p className="text-white text-center">Buy Petrol Coins during the pre sale for a special price - Contract Address:</p>
            <p className="text-white text-center break-words">{ctx.keyResources.PetrolCoinSmartContract}</p>
            <p className="text-white text-center">Price per Token: <b>{ctx.gameConfigurations.TokenPriceInBNBPreSale}</b> BNB</p>
            <p className="text-white text-center">Minimum Purchase: <b>{ctx.gameConfigurations.MinimumPreSale}</b> BNB</p>
            <p className="text-white text-center">Total: <b>{CurrencyFormat(totalAmount, +ctx.gameConfigurations.SystemPrecision)}</b> USDT</p>
            <div className="md:flex md:h-12 md:space-x-6">
                <p className="text-white whitespace-nowrap m-auto">Amount in BNB:</p>
                <CurrencyInput className="h-12 w-full px-2 text-base rounded-full text-white italic text-center bg-transparent shadow-inner shadow-gray-500 border border-gray-700 m-auto" 
                    name="amount" 
                    placeholder="enter the desired value here"
                    allowDecimals={true}
                    allowNegativeValue={false}
                    decimalsLimit={+ctx.gameConfigurations.BNBPrecisionPreSale}
                    decimalScale={+ctx.gameConfigurations.BNBPrecisionPreSale}
                    decimalSeparator="." 
                    groupSeparator=","
                    disableGroupSeparators={true}
                    value={amount} 
                    onValueChange={amountChangeHandler}
                />
                <p className="text-white whitespace-nowrap m-auto md:space-y-6">Whitelist Code:</p>
                <input
                    type="text"
                    className="h-12 w-full px-2 text-base rounded-full text-white italic text-center bg-transparent shadow-inner shadow-gray-500 border border-gray-700 m-auto"
                    placeholder="enter your whitelist promotion code here"
                    value={referralChannel}
                    onChange={referralChangeHandler}
                    maxLength="200"
                />
            </div>
            <CPButton onButtonClick={send} parameters={[]} text="Send" />
        </div>
    )
}
export default Presale;